//Post author item
.post-author-item {
	display: flex;
	margin-bottom: $baseline * 2;
	align-items: center;
}

//Post author img
.post-author-img {
	flex-shrink: 0;
	width: $baseline * 4;
	height: $baseline * 4;
	margin-right: 17px;

	img {
		border-radius: 100%;
	}
}

a.post-author-img {
	&:hover {
		img {
			opacity: 0.7;
		}
	}
}

//Post author text
.post-author-text {
	p {
		margin-bottom: 0;
	}
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	.post-author-item {
		flex-direction: column;
		align-items: flex-start;
	}

	.post-author-img {
		margin-left: 0;
		margin-bottom: $baseline;
	}
}
