/*
//Desktop - from 1200px up
@media (min-width: map-get($grid-breakpoints, xl)) {
}

//Up to tablet landscape - up to 1199px
@media (max-width: map-get($grid-breakpoints, xl) - 1px) {
}

//Tablet landscape - from 992px to 1199px
@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1px) {
}

//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
}

//Tablet portrait - from 768px to 991px
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {
}

//Up to phone landscape - up to 767px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {
}

//Phone landscape - from  576px to 767px
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
}
*/
