.village-tables {
  display: flex;
  flex-direction: column;
  padding-bottom: 132px;

  a:hover {
    text-decoration: none;
  }

  .village-heading-1 {
    padding-top: 34px;
    padding-bottom: 24px;
    font-size: 1.6em;
  }

  .village-heading-2 {
    padding-top: 64px;
    padding-bottom: 24px;
    font-size: 1.6em;
  }

  .village-table-1 {
    max-width: 881px;

    .village-table-year {
      border-right: solid 1px #d7d7d7;
      padding: 12px 20px;
      width: 269px;
      text-align: left;
    }

    td {}
  }

  .village-table-2 {
    //max-width: 1283px;

    .village-table-year {
      border-right: solid 1px #d7d7d7;
      padding: 12px 20px;
      width: 223px;
      text-align: left;
    }
    .table-year {
      width: 111px;
    }
    .table-ribbon {
      width: 189px;
    }



    table th:first-child {
      background-color: #257ce0;;
    }

    table td:first-child {
      background-color: white;
    }
  }





  table {
    td {
      border: solid 1px #d7d7d7;
    }

    thead {
      border-radius: 3px;
      border: solid 1px #9fb0e3;
      background-color: #257ce0;

      th {
        border-right: solid 1px #ffffff;
        border-bottom: solid 1px #9fb0e3;
        color: $white;
        font-weight: 600;
        padding: 12px 20px;
        width: 203px;
        text-align: center;

        &:last-child{
          border-right: solid 1px #9fb0e3;
        }
      }
    }
    tbody {
      border: solid 1px #d7d7d7;
      background-color: #ffffff;
      text-align: center;

      th {
        padding: 12px 20px;
        text-align: center;
      }
    }
  }
}

.village-tables.container {

  background: #f7f7f7;
  border-radius: 10px;
  border: solid 1px #f1f4ff;
  margin: 0 auto 3em auto;
  padding: 0em 2em 2em 2em;
}