#nutsUrovneSelect {
  display: flex;
  flex-direction: column;

  > label {

  }
  > .nuts-selects{
    display: flex;
    flex-direction: row;
    select {
      margin:0 0 5px 0;
      padding: 0 0 0 12px;

      &:first-of-type {
        margin-right: 12px;

      }
    }
    #uroven {
      min-width: unset;
      width: 130px;
    }
  }
}

#nutsUrovneSelect.inline{
  flex-direction: row;
}

//Up to phone landscape - up to 767px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {
  #nutsUrovneSelect {
    > .nuts-selects{
      flex-direction: column;
      #uroven, select {
        width: 100%;
        max-width: unset;
      }
    }
  }

  #nutsUrovneSelect.inline{
    flex-direction: column;
  }
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
}
