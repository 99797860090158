//Partners section
.partners-section {
	.row {
		justify-content: center;
	}
}

//Partners item
.partners-item {
	display: block;
	//max-height: $baseline * 4;
	width: calc(99.5% / 6);
	text-align: center;

	padding: 0 20px;
	margin-bottom: 20px;

	img {
		max-width: 100%;
		max-height: 100%;
		display: inline-block;
		top: 50%;
		position: relative;
		transform: translateY(-50%);
	}

	img[src$=".svg"] {
		height: 100%;
		width: 100%;
	}
}

//Small version
.col-lg-6 .partners-section {
	.container {
		padding: 0;
	}

	.base-heading {
		@extend .base-subheading;
		text-align: left;
	}

	//Partners item
	.partners-item {
		width: calc(99.5% / 3);
	}
}

//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {

	//Small version
	.col-lg-6 .partners-section {
		padding-top: $baseline * 2;

		.base-heading {
			max-width: none;
			margin-bottom: $baseline * 2 !important;
			text-align: center;
		}

		//Partners item
		.partners-item {
			width: calc(99.5% / 6);
		}
	}
}

//Up to phone landscape - up to 767px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {

	//Partners item
	.partners-item {
		width: calc(99.5% / 3) !important;
	}
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {

	//Partners item
	.partners-item {
		width: calc(99.5% / 2) !important;
	}
}