.not-found{

  p{
 font-size: 28px;
 font-weight: bold;
 color: $color-text;
 text-align: center;
 padding-top: 86px;
 padding-bottom: 150px;
   margin-bottom: 0px;
  }
}

//Tablet portrait - from 768px to 991px
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {
}

//Up to phone landscape - up to 767px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {
}

//Phone landscape - from  576px to 767px
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
  .not-found{
    p{
   font-size: 23px;
   margin: 0px 10px;
    }
  }
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
  .not-found{
    p{
   font-size: 20px;
   margin: 0px 10px;
    }
  }
}
