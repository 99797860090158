.image-block-item1 {
	a {
		background-image: url("../../images/new/backpanel1.png");
		background-repeat: no-repeat;
		background-position: bottom right;
		background-size: 30%;
		opacity: .5;
	}
}

.image-block-item2 {
	a {
		background-image: url("../../images/new/backpanel2.png");
		background-repeat: no-repeat;
		background-position: bottom right;
		background-size: 30%;
		opacity: .5;
	}
}

.image-block-item3 {
	a {
		background-image: url("../../images/new/backpanel3.png");
		background-repeat: no-repeat;
		background-position: bottom right;
		background-size: 40%;
		opacity: .5;
	}
}

.triple-block {
	padding-top: 70px;
	padding-bottom: 48px;

	.blocks {
		flex-wrap: wrap;
		flex: 1;
		display: flex;
		gap: 2em 4em;
	}
}

.image-block {
	min-height: 226px;
	margin-bottom: 16px;
	flex: 1;
	a {
		border-radius: 10px;
		box-shadow: 0px 0px 36px -20px #999;
		display: block;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		text-align: center;

		justify-content: center;
		align-items: center;

		text-decoration: none;

		padding: 15px;

		&:hover {
			h2::after {
				content: '';
				padding-bottom: 19px;
				border-bottom: 1px solid #FFF;
				width: 70px;
				margin: auto;
				display: block;
				height: 2px;
			}
			a, p, h2 {
				color: #fff;
			}
			background-color: #257ce0;
			opacity: 1;

		}

	}

	h2 {
		color: #000;
		margin-bottom: 17px;
		font-weight: 600;
		font-size: 1.6em;
		max-width: 250px;
		text-decoration: none;
	}

	h2::after {
		content: '';
		padding-bottom: 19px;
		border-bottom: 1px solid #000;
		width: 70px;
		margin: auto;
		display: block;
		height: 2px;
	}

	p {
		max-width: 250px;
		margin-bottom: 10px;
	}
}

//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
	.image-block {
		min-height: 0;

		a {
			padding: 20px 15px;
		}

		h2 {
			font-size: 22px;
		}
	}
}

//Up to phone landscape - up to 767px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {
	.image-block {
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}
}
