//Advantage block
.advantage-block {
	text-align: center;
	margin-bottom: $baseline * 2;
	padding: 0 15px;
	width: 16.5%;
}

//Advantage inner
.advantage-inner {
	height: 100%;
}

//Advantage img
.advantage-img {
	background: $advantages-img-bg-color;
	height: $baseline * 5;
	width: $baseline * 5;
	margin-bottom: $baseline;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: $advantages-border-radius;

	box-shadow: inset 0 0 0 $advanteges-border-width $advantages-border-color;

	svg {
		height: $advantages-ico-size;
		width: $advantages-ico-size;
		fill: $advantages-ico-fill-color;
	}
}

//Advantage text
.advantage-text {
	& > * {
		font-size: $base-text-font-size;
		transform: translateY($base-text-offset);
		font-weight: 700;
		color: $color-text;
	}
}

.triple-block .row {
	//margin-left: 38px;
	//margin-right: 38px;
}

//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
	//Advantage block
	.advantage-block {
		width: 33%;
	}
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	//Advantage block
	.advantage-block {
		width: 48%;
	}
}
