img {
	display: block;
	-ms-interpolation-mode: bicubic;
	opacity: 1;
	transition: opacity 0.3s ease;
	height: auto;
	max-width: 100%;

	&[data-src]:not(.loaded) {
		opacity: 0;
	}

	&[data-src],
	&[data-srcset] {
		display: block;
		min-height: 1px;
		min-width: 1px;
	}
}

//IMG object fit
.object-fit {
	width: 100%;
	height: 100%;
	object-fit: cover;
	font-family: "object-fit: cover;";
}

//IMG placeholder
.img-placeholder {
	background: $color-bg-01;
}

//To fight Mozilla's default styling
*::placeholder {
	opacity: 1;
}

label {
	margin-bottom: 0;
}

//Embed iframe
iframe {
	margin-bottom: $baseline;
	max-width: 100%;
}

code {
	display: block;
	transform: translateY(1px);
}
