/* purgecss start ignore */
#projectNotices {
	text-align: center;
	p {
		padding: $baseline / 2;
		border-radius: $border-radius-base;
		transform: none;

		&:first-child {
			margin-top: $baseline;
		}

		&.success {
			color: #3c763d;
			background-color: #dff0d8;
			border-color: #d6e9c6;
		}

		&.error {
			color: #a94442;
			background-color: #f2dede;
			border-color: #ebccd1;
		}

		&.warning {
			color: #8a6d3b;
			background-color: #fcf8e3;
			border-color: #faebcc;
		}

		&.info {
			color: #31708f;
			background-color: #d9edf7;
			border-color: #bce8f1;
		}
	}
}
/* purgecss end ignore */
