//Pagination
.ajax-pagination.loading{
	cursor: progress;
	filter : opacity(50%) ;
	transition: filter 0.2s ease-in-out;
	ul li , a, button {
		cursor: progress !important;
	}
}

.pagination-section{
	.pagination{
		padding-bottom: 18px;
		display: block;
	  	margin: 0 auto;
	}

	ul {
		//margin: 0 0 rem(60px);
		font-size: 0;
		text-align: right;
		padding-bottom: 14px;

		&.pagination li {
			display: inline-block;
			vertical-align: top;
			//font-size: rem(18px);
			font-weight: bold;
			margin: 4px 3px;

			&.active {
				background-color: #fff;
				border: 1px solid #969696;
				color: #6b6b6b;
				text-decoration: none;
			}
			&.prev {
				img {
					width: 13px;
					height: 16px;
				}
			}
				&.next {
					img {
						width: 13px;
						height: 17px;
					}
			}

			&.next {
				.a {
					transform: rotate(180deg);
					transform-origin: center center;
				}
			}
		}

		&.pagination a {
			position: relative;
			border-radius: 4px;
			display: block;
			text-align: center;
			//width: rem(42px);
			//height: rem(42px);
			//line-height: rem(42px);
			margin-left: -1px;
			color: #6b6b6b;
			text-decoration: none;
		}
	}

	ul.pagination a {
		padding: 0;
		font-size: 14px;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		text-decoration: none;
		letter-spacing: 0;
	}

	.end img {
		width: 23px;
		height: 23px;
	}

	.start img {
		width: 23px;
		height: 23px;
		transform: rotate(180deg);
	}

	.pagination li:nth-child(1n+0) {
		padding: 9.5px 15px 9px;
	}

	.pagination > li.prev {
		padding: 10.5px 10px 10.5px 14px;
		transform: rotate(180deg);
	}

	.pagination > li.next {
		padding: 10px 10px 10px 14px;
	}

	.pagination > li.end {
		padding: 7.5px 5.5px 7px 9.5px;
	}

	.pagination > li.start {
		padding: 7.5px 9.5px 5.5px 5px;
	}

	.pagination li {
		border-radius: 3px;
		background-color: #fff;
		border: solid 1px #c4c4c4;
		color: #6b6b6b;
	}

	.pagination li:not(.disabled) {
		&:active,
		&:focus,
		&:hover {
			border: solid 1px #969696;
			background-color: #f5f5f5;
			cursor: pointer;
		}
	}

	.pagination li.disabled a{
		&:hover {
			cursor: default;
		}
	}
	// TEXT
	.pagination-column p {
		font-family: "OpenSans", sans-serif;
		font-size: 14px;
		font-style: italic;
		color: #6b6b6b;
		text-align: right;
		padding-right: 18px;
		padding-top: 15px;
		margin-bottom: 20px;
	}

	.pagination-column {
		padding: 0;
	}


	//Desktop - from 1200px up
	@media (min-width: map-get($grid-breakpoints, xl)) {
	}

	//Up to tablet landscape - up to 1199px
	@media (max-width: map-get($grid-breakpoints, xl) - 1px) {
	}

	//Tablet landscape - from 992px to 1199px
	@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1px) {
	}

	//Up to tablet portrait - up to 991px
	@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
	}

	//Tablet portrait - from 768px to 991px
	@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {

	}


	//Up to phone landscape - up to 767px
	@media (max-width: map-get($grid-breakpoints, md) - 1px) {
	}

	//Phone landscape - from  576px to 767px
	@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
		.pagination-column p {
			padding-right: 62px;
		}
	}

	//Phone portrait - up to 575px
	@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
		.pagination-column p {
			padding-right: 62px;
		}
	}
}
