.search-section {
	padding-bottom: 30px;
}

.search-section .container {
	.row {
		margin-left: -8px;
		margin-right: -8px;
	}
	background-color: #f7f7f7;
	padding: 0 10em 2em;
	border-radius: 10px;

}

.search-section h2 {
	font-family: "OpenSans", sans-serif;
	font-size: 28px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	padding-top: 56px;
	padding-bottom: 40px;
	color: #1c2435;
	text-align: center;
}

.container .search-block {
	padding: 0 0;
	margin-bottom: 3em;
	min-height: 125px;
	padding-top: .1px;
	a {
		border-radius: 0px;
		outline: solid 2px #eaeaea;
		text-align: center;
		background-color: #fff;
		height: 100%;
		text-decoration: none;
		display: block;
		padding-top: .1px;

		img {
			display: block;
			height: 54px;
			padding-top: .1px;
			margin: auto;
			margin-top: 60px;
		}
		img.vesnice_roku {
			height: 30px;
			margin-top: 84px;
		}
	}
}
.container .chapter-indicator-section .search-block {
	margin-bottom: 1em;

	a p {
		padding-bottom: 0px;
	}
}

.search-block a {
	margin-left: auto;
	margin-right: auto;
	&:hover {
		box-shadow: 0px 0px 8px 6px #999;
		z-index: 100;
		position: relative;
	}

	p {
		font-family: "OpenSans", sans-serif;
		font-size: 20px;
		font-weight: 300;
		color: #000;
		letter-spacing: 0;
		padding-top: 35px;
		padding-bottom: 35px;
		margin: auto;
		text-decoration: none;
		margin-bottom: 0;

		max-width: 220px;

		transform: none;
	}
}



.chapter-indicator .search-block {
	padding: 0 0;
	//margin-bottom: 3em;
	height: 120px;
	padding-top: .1px;
	a {
		border-radius: 0px;
		outline: solid 2px #eaeaea;
		text-align: center;
		background-color: #fff;
		height: 100%;
		text-decoration: none;
		display: block;
		padding-top: .1px;

		img {
			display: block;
			height: 54px;
			padding-top: .1px;
			margin: auto;
			margin-top: 60px;
		}
		img.vesnice_roku {
			height: 30px;
			margin-top: 84px;
		}
	}
}

.chapter-indicator .search-block a {
	margin-left: auto;
	margin-right: auto;
	&:hover {
		box-shadow: 0px 0px 8px 6px #999;
		z-index: 100;
		position: relative;
	}

	p {
		font-family: "OpenSans", sans-serif;
		font-size: 20px;
		font-weight: 300;
		color: #000;
		letter-spacing: 0;

		margin: auto;
		text-decoration: none;
		margin-bottom: 0;
		padding: 0;
		max-width: 220px;
		text-align: center;
		position: relative;
		top: 50%;
		-ms-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}

@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	.container .search-block {
		min-height: initial;
	}

	.search-block a {
		p {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
}

//Desktop - from 1200px up
@media (min-width: map-get($grid-breakpoints, xl)) {
}
//Up to tablet landscape - up to 1199px
@media (max-width: map-get($grid-breakpoints, xl) - 1px) {
}
//Tablet landscape - from 992px to 1199px
@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1px) {
}
//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
}
//Tablet portrait - from 768px to 991px
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {
}
//Up to phone landscape - up to 767px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {
}
//Phone landscape - from  576px to 767px
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
}
//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	.search-section {
		padding-bottom: 20px;
	}

	.search-block {
		max-width: 300px;
		margin-left: auto;
		margin-right: auto;
	}
}
