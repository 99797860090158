.login-box{
  border-radius: 0.1875rem;
  border: solid 1px #f1f4ff;
  background-color: #fbfbfb;
  margin: 40px auto 1.9375rem;
  max-width: max-content;
  padding: 0 2em;

  .login-box-inner{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    max-width: 412px;
    margin: 30px auto 20px;
  }

  h1{
    margin-bottom: 10px;
  }

  form {
    display: flex;
    flex-direction: column;

    .login-input-group{
      display: flex;
      align-self: center;
      align-items: center;

      label{
        width: 65px;
        text-align: right;
        margin-right: 10px;
        font-weight: 700;
      }

      input[type=text], input[type=password] {
        display: inline-flex;
        flex: 1;
        width: 300px;
        height: 38px;
        padding: 9px 13px 10px 13px;
        margin: 23px 0px;
        border-radius: 3px;
        border: solid 1px #b9b9b9;
        background-color: #ffffff;
        outline: 0;
      }
    }

    button{
      width: fit-content;
      align-self: flex-end;
      margin-top: 10px;
    }
  }
}

@media (max-width: 509px) {
  .login-box{
    form {
      .login-input-group {
        flex-direction: column;

        label {
          text-align: left;
          width: 100%;
          margin-top: 15px;
        }

        input[type=text], input[type=password] {
          width: 100%;
          margin-top: 5px;
        }
      }
    }
  }
}