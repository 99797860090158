//Baseline grid visualization
html {
	&.js-baseline {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			top: -1px;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(rgb(11, 226, 215) 1px, transparent 1px);
			background-size: auto $baseline;
			z-index: 998;
		}
	}
}

.baseline-toggle {
	display: inline-block;
	line-height: $baseline * 2;
	font-size: 15px;
	font-weight: 600;
	padding: 0 13px;
	position: fixed;
	bottom: 5px;
	right: 5px;
	cursor: pointer;
	background: $color-white;
	user-select: none;
	z-index: 999;
	border: 2px solid $color-black;
	color: $color-black;
	border-radius: 4px;
	transition: all 0.3s ease;

	&:hover {
		background: $color-black;
		color: $color-white;
	}
}
