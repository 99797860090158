.statistical {
  display: flex;
  flex-direction: column;

  .statistical-counter{
    max-width: 648px;
    text-align: right;
    font-size: 14px;
    font-style: italic;
    color: #6b6b6b;
    padding-top: 10px;
  }

  table {
    //max-width: 633px;
    border: solid 1px;

    thead {
      border-radius: 3px;
      border: solid 1px #9fb0e3;
      background-color: #11347e;

      th {
        padding-left: 31px;
        color: $white;
        font-weight: 600;
        padding-top: 13px;
        padding-bottom: 11px;

      }
    }

    tbody {
      td {
        border: solid 1px #d7d7d7;
        background-color: #ffffff;
        &.dark-bck{
          background-color: #f4f7f6;
        }
      }

      tr {

        .left-part {
          text-align: center;
          padding: 12px 0;
          color: #1a2439;
        }

        .right-part {
          text-align: left;
          padding-left: 14px;
        }
      }
    }
  }
}


//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
  .statistical {
    display: flex;
    flex-direction: column;

    .statistical-counter{
      max-width: 648px;
      text-align: right;
      font-size: 14px;
      font-style: italic;
      color: #6b6b6b;
      padding-top: 10px;
      padding-bottom: 46px;
    }


    table {
      max-width: 633px;
      border: solid 1px;
      font-size: 14px;


      thead {
        border-radius: 3px;
        border: solid 1px #9fb0e3;
        background-color: #11347e;

        th {
          padding-left: 31px;
          color: $white;
          font-weight: 600;
          padding-top: 13px;
          padding-bottom: 11px;

        }
      }

      tbody {
        td {
          border: solid 1px #d7d7d7;
          background-color: #ffffff;
          &.dark-bck{
            background-color: #f4f7f6;
          }
        }



      }
    }
  }
}
