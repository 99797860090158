
html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100%;
}

@media (min-width: 768px) {
  .col-md-1_5 {
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

#background{
  position: absolute;
  z-index: -100;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}

.navigation > .navigation-items > section{
  width: 100%;
}

.flex-line-break{
  flex-basis: 100%;
  height: 0;
}

// todo check if working correctly
select.default-select {
  min-width: 217px;
  //height: 38px;
  padding: 0px 18px 0px;
  margin: 23px 12px 23px 0;
  border-radius: 3px;
  border: solid 1px #b9b9b9;
  background-color: #ffffff;
  outline: 0;
}

.krajske-ris-content-container{
  //background-color: white;
  border-radius: 3px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-right: 0;
  img {
    padding-bottom: unset;
    display: inline-block;
  }
  .entry-content .table-responsive {
      overflow-x: scroll;
      table {
        white-space: nowrap;
      }
  }
}
@media (max-width: 767px){
  .krajske-ris-content-container{
    margin-right: -15px;
    border-radius: 0;
  }
}

// village search list
a.pagination-header-with-order{
  color: white;
  text-decoration: none;
  tab-index: 0;
  span{
    margin-right: 4px;
    text-decoration: underline;
  }
  img.direction{
    display: inline-block;
    filter: brightness(0) invert(1);
  }
  img.direction.asc{
    transform: rotate(-90deg);
  }
  img.direction.desc{
    transform: rotate(90deg);
  }
  &:hover, &:focus{
    span{
      text-decoration: none;
    }
  }
}

// Sticky table grid
// usage example in villageOfTheYearSearch.html.twig
.table-grid {
  width: 100%;
  display: grid;
}
.table-sticky {
  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  table th:first-child, table td:first-child {
    position: sticky;
    left: 0px;
    z-index: 2;
  }
}

// village detail
.city-tabs .navigation-section {
  min-width: unset;
}
.city-tabs #pills-euroregiony-div p{
  margin-bottom: 0;
}
.city-tabs #pills-samosprava-div .navigation-section{
  padding-bottom: 15px;
}
.table{
  margin-bottom: unset;
}
span.table-dates{
  font-weight: 100;
}
.city-detail {
  .city-images {
    img {
      max-height: 240px;
    }
    iframe {
      height: 240px;
      width: 100%;
    }
  }
  .village-of-the-year {
    li img {
      display: inline;
    }
  }
}


.village-detail-edit-form {
  form{
    width: 100%;
  }
  input {
    display: block;
  }

  button[type="submit"]{
    display: block;
    margin-left: auto;
  }
}

.village-detail-print{
  font-weight: 400;
  font-size: 14px;
  padding: 11px 12px;
  margin-top: 15px;
  a {
    text-decoration: none;
  }
  img {
    width: 18px;
    display: inline-block;
  }
}

#city-detail-sources {
  h3 {
    margin-top: 1em;
    margin-bottom: .5em;
    font-size: 1.1em;
  }
  ul li{
    margin: 0;
  }
}

.village-detail-table{
  margin-top: 20px;
  h5{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  table {
    line-height: 1.1em;
  }
  table, th, td {
    border: 1px solid lightgrey;
    min-width: 2rem;
    text-align: left;
  }
}
.village-search select{
  padding: 7px 18px 7px;
}

.gray-background-highlight{
  word-wrap: break-word;
  background: #f7f7f7;
  border-radius: 10px;
  border: solid 1px #f1f4ff;
  padding: 0 1rem 0.5rem;
  margin: 0.5rem -1rem 1.5rem;
}

.statistical-results{
  th{
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }
  th:first-of-type{
    text-align: left;
    padding-left: 31px;
  }

  td{
    text-align: center;
  }
}

.table-result-counter{
    text-align: right;
    font-size: 14px;
    font-style: italic;
    color: #6b6b6b;
    margin-bottom: 12px;
}

.footer-main {
  grid-row-start: 2;
  grid-row-end: 3;
}

.mas-block {
  border-collapse: collapse;
  border-style: hidden;
}

.mas-block td {
  border-bottom: 1px solid rgb(222, 226, 230);
  padding-top: 15px;
  padding-bottom: 15px;
}

.mas-block .row {
  margin: 0px;
}

.city-flag {
  object-fit: contain;
  height: 140px;
  width: auto;
  position: relative;
  margin: auto;
}
.city-symbol {
  object-fit: contain;
  height: 140px;
  width: auto;
  position: relative;
  margin: auto;
}

.entry-content img {
  margin: initial;
  width: initial;
}

@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
 .nav.nav-pills .nav-item {
   display: block;
   width: 100%;
   text-align: center;
 }
}

@media (max-width: map-get($grid-breakpoints, md) - 1px) {
  .ignore-text-right-md .text-right {
    text-align: inherit !important;
  }

  .special-flex-md .col-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.soft-margin {
  margin: 20px;
}

.map-content-margin {
  article p, article img {
    margin-bottom: 0px;
  }

  article {
    margin-bottom: 35px;
  }
}


@media (max-width: map-get($grid-breakpoints, md) - 1px) {
  h1 {
    padding-top: 28px !important;
  }
}

/* trumbowyg */
#trumbowyg-icons {
  display: none;
}

.trumbowyg-editor table td {
  border-color: inherit !important;
}

#page-editor-layout.page-editor-hidden .trumbowyg {
  display: none !important;
}

.page-save, .page-cancel {
  display: none;
  float: left;
  margin: 3px;
}

.sheet-ref {
  background-color: yellow;
  clear: both;
  width: 100%;
  border-radius: 5px;
  border: 1px dashed orange;
  margin: 3px;
  padding: 3px;
  text-align: center;
  font-weight: bold;
}

#page-content .sheet-ref {
  all: unset;
  display: none;
}

.page-controls {
  .btn::after {
    all: unset;
  }
}

.search_eu_projects .modal-content{
  font-size: 16px;
  .table-responsive {
    table {
      width: 100%;
      tr td:first-child {
        padding-right: 10px;
      }
    }
  }
}

.modal-content a.close:hover span {
  color: black;
}

.modal-iframe{
  height: 409px;
  margin-top: 0.5rem;
}

.map-controls-inline {
    a, form, button {
      min-width: unset;
      display: inline-block;
    }
}

// temporary submenu
#dropdownMenuGrantsWrapper {
  .dropdown-menu{
    display: none;
    background-color: #ba1828;
    //margin-top: 1rem;
    margin-left: -10px;
    padding: 5px;
    margin-top: 0;
    a {
      margin: 4px;
    }
    a:hover {
      background-color: inherit;
      color: white;
      text-decoration: none;
    }
  }
  &:hover > .dropdown-menu {
    display: block;
  }
}

#project-intentions-search-form {
  .project-intention-categories{
    justify-content: flex-start;
    select:first-of-type {
      margin-right: 10px;
    }
    select {
      flex-grow: 1;
      width: 100%;
      max-width: 389.5px;
    }
  }
  .btn-wrap{
    display: block;
    text-align: center;
  }
}

#logo {
    margin-top: 20px;
}
.header-block-middle::after {
  content: '';
  position: relative;
  display: block;
   width: 1px;
  right: -5px;
  margin-top: -7px;
  height: 72px;
  border-right: 1px solid #11347e;
  padding-left: 4px;
}

img.icon-pdf {
  display: inline-block;
  height: 27px;
  width: 26px;
  border: 1px solid #7f7f7f;
  border-radius: 3px;
  padding: 9px 4px;
}

img.icon-web {
  height: 27px;
  width: 26px;
  border: 1px solid #7f7f7f;
  padding: 5px 5px;
}

#pills-pro-div-content a {
  font-size: 1rem;
}

#grant-modal-guide-modal .modal-content .grant-opportunities-search-item {
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  label, select {
    display: block;
    width: 100%;
    padding-bottom: 0.2rem;
  }
}

#eu-grant-opportunities-search-form .administrative-search-item{
  label {
    width: 205px;
  }
  input, select{
    width: calc(100% - 205px);
    max-width: unset;
  }

  select:disabled {
    border-color: gainsboro;
  }

  @media (max-width: map-get($grid-breakpoints, md) - 1px) {
    input, select, label {
      width: 100%;
    }
  }
}

#pills-smart-div {
  li{
    font-size: 0.8rem;
    margin-bottom: 0.6rem;
  }

  .gray-background-highlight {
    padding: 1rem;
    font-size: 1rem;
    .smart-img-wrapper {
      max-height: 250px;
    }
    .smart-texts{

    }
    img {
      max-height: 100%;
      max-width: 100%;
      margin: auto;
      object-fit: contain;
    }
  }
}

.clickable[data-clickable-url]{
  cursor: pointer;
}

.clickable[data-clickable-url]:hover {
  border-radius: 3px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
  transition: all 200ms ease-out;
}

.loading-spinner {
  width: 3rem;
  margin: 4rem auto;
  animation: spinner 1s cubic-bezier(.41,.71,.65,.36) infinite;
  //animation: spinner 1s steps(8, end) infinite;
  @keyframes spinner {
    to { transform: rotate(360deg); }
  }
}

.administrative-section p.updated,
.project-filter-section p.updated {
  font-family: "OpenSans", sans-serif;
  font-size: 14px;
  font-style: italic;
  color: #6b6b6b;
  margin-left: 1em;
}

#regional-grant-search-form{
  label{
    padding: 0;
    text-align: end;
    padding-right: 0.5rem;
  }
  input, select {
    width: 100%;
    margin: 23px 12px 23px 0;
    max-width: unset;
  }
}

#pills-budget-div table.rud-table-single{
  margin-top: 1em;
  margin-bottom: 1em;
  border: none;
  td{
    border: none;
  }
  td{
    //margin: 2px;
    line-height: initial;
  }
  td:nth-child(2) {
    white-space: nowrap;
    text-align: right;
  }
  tr:nth-child(1) {
    text-align: center;
  }
  tr:nth-child(2) {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
  }
}
