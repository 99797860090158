//Footer main
.footer-main {
	background: $footer-bg-color;
	margin-left: auto;
	margin-right: auto;
	margin-top:auto;

	//margin-top: 10px;
	//bottom:0;
	width: 100%;
	//position: absolute;
}

//Footer brilo
.footer-brilo {
	background: $footer-brilo-bg;

	.container {
		display: flex;
		justify-content: center;
	}

	p{
		color: #fff!important;
	}

	a {
		padding: 0 $baseline * 0.5;
		display: flex;
		align-items: center;
		justify-content: center;
		height: $baseline * 1.5;
		text-decoration: none;

		&:hover {
			text-decoration: none;

			span {
				text-decoration: underline;
			}
		}
	}

	span {
		color: $footer-brilo-text-color;
		font-size: 14px;
		margin-right: 10px;
		transform: translateY(1px);
	}

	img {
		height: $baseline * 0.8;
	}
}
