//Post detail header
.post-detail-header {
}

.post-detail-header-heading {
	//margin-bottom: $baseline;
}

//Post detail header bar
.post-detal-header-bar {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	font-size: $smallest-text-font-size;
	margin-bottom: $baseline * 0.5;

	.author {
		display: flex;
		align-items: center;
		margin-right: 25px;
		margin-bottom: $baseline * 0.5;

		img {
			height: $baseline * 2;
			margin-right: 10px;
			border-radius: 100%;
		}
	}

	.date {
		margin-right: 25px;
		margin-bottom: $baseline * 0.5;
	}

	.comments {
		color: $color-text;

		display: flex;
		align-items: center;
		margin-right: 25px;
		margin-bottom: $baseline * 0.5;

		img {
			width: 15px;
			height: 15px;
			margin-right: 9px;
		}
	}

	.tags {
		display: flex;
		flex-wrap: wrap;
	}

	.posts-item-tag {
		margin-bottom: $baseline * 0.5;
	}
}
