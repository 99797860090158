.simple-pagination{
  display: flex;
  justify-content: space-between;
  .count {

  }
  .pagination-links{
    li {
      margin-left: 6px;
    }
  }
}

.pagination-overlay {
  position: relative;
  float: right;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: rgba(0,0,0,0.1);
  //z-index: 2;
  cursor: wait;
  display:none;
}