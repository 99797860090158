.industry {
  display: block;
  box-sizing: border-box;
  margin: 0 auto;

  h1 {
    padding-top: 54px;
    padding-bottom: 16px;
    margin-left: -16.5px;
  }

  p {
    margin-left: -15.5px;
    color: #112240;
  }

  i {
    color: #112240;
  }
}

.industry-section {
  margin: auto;
  display: flex-wrap;

  p {
    padding-bottom: 16px;
  }

  form {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
}

.industry-search {
  width: 1283px;
  height: 100%;
  margin-bottom: 30px;
  border-radius: 3px;
  border: solid 1px #f1f4ff;
  background-color: #fbfbfb;

  .btn-wrap{
    padding-left: 20px;
  }

  label {
    display: flex;
    align-items: center;
    padding-right: 18px;
    padding-left: 60px;
  }

  input[type=text] {
    display:flex;
    max-width: 217px;
    height: 38px;
    padding: 9px 13px 10px;
    margin: 23px 0px 23px 0;
    border-radius: 3px;
    border: solid 1px #b9b9b9;
    background-color: #ffffff;
    outline: 0;
  }

  select {
    min-width: 217px;
    height: 38px;
    padding: 9px 18px 10px;
    margin: 23px 12px 23px 0;
    border-radius: 3px;
    border: solid 1px #b9b9b9;
    background-color: #ffffff;
    outline: 0;
  }

  button[type=submit] {
    margin: 20px 0px 20px 0px;

    cursor: pointer;

    &:hover {
      color: white;
    }
  }
}

.industry-search-item{
    display: flex;
    flex-direction: row;
}
//Desktop - from 1200px up
@media (min-width: map-get($grid-breakpoints, xl)) {}
//Up to tablet landscape - up to 1199px
@media (max-width: map-get($grid-breakpoints, xl) - 1px) {


}
//Tablet landscape - from 992px to 1199px
@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1px) {
  .industry-search-box {

        align-items: center;
        justify-content: center;

}
}
//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {}
//Tablet portrait - from 768px to 991px
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {}
//Up to phone landscape - up to 767px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {}
//Phone landscape - from  576px to 767px
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {}
//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {}
