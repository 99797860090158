.administrative {
  display: block;
  box-sizing: border-box;
  margin: 0 auto;

  article{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  h1 {
    padding-top: 54px;
    padding-bottom: 16px;
    margin-left: -16.5px;
    font-size: 1.8em;
  }

  p.subheader {
    margin-left: -15.5px;
    color: #112240;
  }

  i {
    color: #112240;
  }
  a{
    &:hover{
      text-decoration: none;
    }
  }
}

.administrative-section {
  margin: auto;
  display: flex-wrap;

  p {
    padding-bottom: 16px;
  }

  form {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
}

.administrative-search {
  /*width: 1283px;*/
  height: 100%;
  margin-bottom: 30px;
  border-radius: 3px;
  border: solid 1px #f1f4ff;
  background-color: #fbfbfb;

  .btn-wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  label {
    display: flex;
    align-items: center;
    padding-right: 15px;
    padding-left: 40px;
    min-width: 130px;
  }

  select#category{
    max-width: 250px;
  }

  input[type=text] {
    display:flex;
    max-width: 217px;
    height: 38px;
    padding: 0px 13px 0px;
    //margin: 23px 0 23px 0;
    border-radius: 3px;
    border: solid 1px #b9b9b9;
    background-color: #ffffff;
    outline: 0;
  }

  select {
    min-width: 217px;
    height: 38px;
    padding: 0px 18px 0px;
    margin: 23px 12px 23px 0;
    border-radius: 3px;
    border: solid 1px #b9b9b9;
    background-color: #ffffff;
    color: black;
    outline: 0;
  }

  button[type=submit] {
    margin: 20px 0px 20px 0px;

    cursor: pointer;

    &:hover {
      color: #2477d7;
    }
  }
}

.administrative-search-item{
    display: flex;
    flex-direction: row;
}

//Desktop - from 1200px up
@media (min-width: map-get($grid-breakpoints, xl)) {}
//Up to tablet landscape - up to 1199px
@media (max-width: map-get($grid-breakpoints, xl) - 1px) {


}
//Tablet landscape - from 992px to 1199px
@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1px) {
  .administrative-search-box {

        align-items: center;
        justify-content: center;

}
}
//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {}
//Tablet portrait - from 768px to 991px
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {}
//Up to phone landscape - up to 767px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {}
//Phone landscape - from  576px to 767px
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {}
//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {
  .administrative-search-item {
    width: 100%;
    display: initial;
    select, input#search, select#category, input:not([type=button]):not([type=submit]){
      width: 100%;
      max-width: unset;
      margin-right: 0px !important;
      margin-left: 0px !important;
      margin-top: 5px !important;
    }
    label{
      margin-top: 20px;
      padding-left: 0px;
      justify-content: left !important;
    }
  }
}


.administrative-search-result-item{
  border-bottom: 1px grey solid;
  padding: 10px 0 10px 0;
  margin: 0 15px 0 15px;

  .result-row{
    margin-top: 10px;
  }

  a.collapse-link{
    text-decoration: none;
    font-weight: 700;
  }

  .result-row-with-label{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    .desc {
      width: 110px;
      font-style: italic;
      font-weight: 200;
    }
    .text{
      flex: 1;
    }
  }

  .list-item-label{
    display: block;
  }
}

.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}