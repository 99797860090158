/* purgecss start ignore */
//Button
.btn {
	border-radius: $button-border-radius;
	font-size: $button-font-size;
	background: $button-primary-bg-color;
	color: #FFF;
	border-radius: 10px;
	font-weight: 300;
	min-width: $button-min-widht;
	text-decoration: none;

	@if $button-uppercase {
		text-transform: uppercase;
	}

	user-select: none;
	display: inline-block;
	border: none;
	vertical-align: top;
	text-align: center;
	cursor: pointer;
	padding: $baseline * 0.5 25px;
	overflow: hidden;
	min-width: 200px;

	&:focus,
	&:active {
		outline: 0;
		color: #FFF;
		text-decoration: none;
	}

	&:hover {
		color: $button-primary-bg-color;
		text-decoration: none;
		background: white;
		box-shadow: 0px 0px 12px 1px #ddd;
		/*&::after {
			transform: scaleY(1);
		}*/
	}

	span {
		position: relative;
		z-index: 1;
		display: block;
	}
}

//Button secondary
.btn-secondary {
	background: $button-secondary-bg-color;
	color: $button-secondary-text-color;
	border-radius: 3px;
	box-shadow: inset 0 -4px 0 0 #11347e;
	border: solid 1px #11347e;

	&:hover {
		span {
			color: $color-white;
		}
	}

	&:focus,
	&:active {
		color: $button-secondary-text-color;
	}

	&::after {
		background: $button-secondary-bg-color-hover;
	}
}

//Button small
.btn-small {
	font-size: $button-small-font-size;
	min-width: $button-small-min-widht;

	padding: $baseline * 0.36 20px;
	margin: $baseline * 0.14 0;
}

//Button large
.btn-large {
	font-size: $button-large-font-size;
	min-width: $button-large-min-widht;

	padding: $baseline * 0.7 20px;
	margin: $baseline * 0.3 0;
}

//Button disabled
.btn-disabled {
	background: $button-disabled-bg-color;
	color: $button-disabled-text-color;

	cursor: default;

	&:hover,
	&:active,
	&:focus {
		color: $button-disabled-text-color;
	}

	&::after {
		display: none;
	}
}

//Loading
.btn-loading {
	color: $button-loading-text-color;
	cursor: default;
	position: relative;

	&::before {
		content: "";
		background-image: repeating-linear-gradient(-45deg, $button-loading-bg-color-01, $button-loading-bg-color-01 11px, $button-loading-bg-color-02 10px, $button-loading-bg-color-02 20px);
		background-size: 28px 28px;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		animation: move 0.5s linear infinite;
	}

	&::after {
		display: none;
	}
}
@keyframes move {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: 28px 0;
	}
}


a.clear-form {
	text-transform: uppercase;
	font-size: 0.9em;
}
/* purgecss end ignore */
