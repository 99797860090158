//Posts section
.posts-section {
	.base-heading {
		margin-bottom: $baseline;
	}

	& > header {
		margin-bottom: $baseline * 2;
	}
}
