/* purgecss start ignore */

//bg-01
.bg-01 {
	background-color: $color-bg-01 !important;
}

//bg-02
.bg-02 {
	background-color: $color-bg-02 !important;
}

//bg-03
.bg-03 {
	background-color: $color-bg-03 !important;
}

//bg-04
.bg-03 {
	background-color: $color-bg-04 !important;
}

//Container narrow
.container-narrow {
	max-width: 1000px;

	//Up to phone landscape - up to 767px
	@media (max-width: map-get($grid-breakpoints, md) - 1px) {
		&:not(.breadcrumbs-container) {
			padding-top: $baseline;
		}
	}
}

//Aside Left/right

.aside-left {
	.aside-main {
		order: 0;
	}

	>div {
		order: 1;
	}
}


/* purgecss end ignore */
