//Signpost item
.signpost-item {
	text-align: center;
	margin-bottom: $baseline * 3;

	&.square .signpost-item-img {
		&::after {
			padding-bottom: 100%;
		}
	}

	&.landscape .signpost-item-img {
		&::after {
			padding-bottom: 100% / 4 * 3;
		}
	}

	&.portrait .signpost-item-img {
		&::after {
			padding-bottom: 100% / 3 * 4;
		}
	}
}

.signpost-item-inner {
	height: 100%;
	display: flex;
	flex-direction: column;
	text-decoration: none;

	&:hover {
		text-decoration: none;

		.signpost-item-img {
			img {
				opacity: 0.7;
			}
		}

		.signpost-item-title {
			color: $color-primary;
			text-decoration: underline;
		}

		.btn {
			@extend .btn:hover;
		}
	}

	&:focus,
	&:active {
		text-decoration: none;
	}
}

.signpost-item-img {
	margin-bottom: $baseline;
	text-align: center;
	flex-grow: 0;
	align-self: center;
	min-height: 1px;
	position: relative;
	width: 100%;
	border-radius: $image-border-radius;
	overflow: hidden;

	background: $image-placeholder-bg-color;

	&::after {
		content: "";
		position: relative;
		display: block;
		width: 100%;
	}

	img {
		border-radius: $image-border-radius;
		transition: opacity 0.2s;
		position: absolute;
		max-width: 100%;
		max-height: 100%;
		transform: translateZ(0);
	}
}

.signpost-item-title {
	color: $color-text;
	margin-bottom: $baseline;
	transition: color 0.2s;
	font-weight: 700;
}

.signpost-item p {
	margin-bottom: $baseline * 2;
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	.signpost-item {
		max-width: 350px;
	}
}
