.statistical{
  display: flex;

  h1 {
    padding-top: 54px;
    padding-bottom: 16px;
		margin-left: -16.5px;
	}
  p{
    margin-left: -15.5px;
  }
}

.statistical-section{
  max-width: 100%;
  margin:auto;
  p{
    padding-bottom: 16px;
  }
}

.statistical-search-box{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 1.9375rem;
  border-radius: 0.1875rem;
  border: solid 1px #f1f4ff;
  background-color: #fbfbfb;

  label{
    padding-right: 15px;
  }

  input[type=text] {
    display: inline-flex;
    flex: 1;
    width: 217px;
    height: 38px;
    padding: 9px 13px 10px 13px;
    margin: 23px 0px;
    margin-right: 30px;
    border-radius: 3px;
    border: solid 1px #b9b9b9;
    background-color: #ffffff;
    outline: 0;
  }
  button[type=submit] {
    display: inline-flex;
    margin: 17px 0px;
    cursor: pointer;
    &:hover{
      color:#2477d7;
    }
  }
  form {
    width: 100%;
    .controls{
      display: flex;
      justify-content: space-between;
    }
  }
  .download-excel{
    margin: 17px 0px;
    text-align: left;
    img {
      height: 1rem;
      display: inline-block;
      margin-right: 5px;
    }
    :hover{
      color: #2477d7;
    }
  }

  #ukazatel{
    height: unset;
  }
}


//Desktop - from 1200px up
@media (min-width: map-get($grid-breakpoints, xl)) {
}

//Up to tablet landscape - up to 1199px
@media (max-width: map-get($grid-breakpoints, xl) - 1px) {
}

//Tablet landscape - from 992px to 1199px
@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1px) {
}

//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
}

//Tablet portrait - from 768px to 991px
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {
  .statistical-search-box{
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

//Up to phone landscape - up to 767px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {

}

//Phone landscape - from  576px to 767px
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
  .statistical-search-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    label{
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 15px;
    }

    input[type=text] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    button[type=submit] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      }
    }
  }

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
  .statistical-search-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    label{
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 15px;
    }

    input[type=text] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    button[type=submit] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      }
    }
}

@media (max-width: map-get($grid-breakpoints, md) - 1px) {
  .statistical-search-box{
      padding-left: 0px;
      padding-right: 0px;

      label {
        align-items: inherit;
        justify-content: inherit;
        text-align: left;
        width: 100%;
      }

    input:not([type=button]):not([type=submit]), select,.default-select {
      margin-top: 0px;
      width: 100%;
    }
  }


}

.search_statistical_data .chapter-indicator-section a {
background-color: white;
}