.project-articles {
	margin-bottom: 26px;
}

.project-articles .container {
	padding: 0 30px;
	padding-bottom: 20px;
}

.project-articles article {
	word-wrap: break-word;
	border-radius: 3px;
	border: solid 1px #f1f4ff;
	background-color: #ffffff;
	margin-bottom: 16px;

	padding-top: 20px;

	.container .container {
		padding: 0;

		p {
			margin-bottom: 5px;
		}
	}
}

.project-articles-section h2 {
	font-family: "OpenSans", sans-serif;
	font-size: 15px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #313131;
	text-decoration: underline;
	padding-bottom: 14px;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}

.project-articles-section p {
	font-family: "OpenSans", sans-serif;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #6b6b6b;
	line-height: 1.2;
}

.project-articles-section strong {
	line-height: 12px;
	margin-bottom: 0px;
}

.project-rows p {
	line-height: 24px;
	margin-bottom: 0px;
}

// TODO az se v projektu vyskytne project-article, pouzit
//.modal-content {
//	padding: 30px 59px 27px 59px;
//
//	p {
//		color: #6b6b6b;
//	}
//
//	table {
//		color: #6b6b6b;
//
//		td {
//			word-break: break-all;
//
//			&:first-child {
//				padding-right: 15px;
//			}
//			&:last-child {
//				font-weight: 700;
//			}
//		}
//
//		th {
//			padding-top: 26px;
//		}
//	}
//}


//Phone landscape - from  576px to 767px
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
	.project-articles article {
		//margin: 18px 60px;
	}
	.project-articles-section p {
		font-size: 14px;
	}
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	.project-articles-section p {
		font-size: 13.5px;
	}

	.project-articles article {
		& > .container {
			padding: 0 15px;
			padding-bottom: 15px;
		}
	}

	// TODO
	//.modal-content {
	//	table {
	//		tr {
	//			display: block;
	//		}
	//
	//		td {
	//			display: block;
	//
	//			&:last-child {
	//				margin-bottom: 20px;
	//			}
	//		}
	//	}
	//}
}
