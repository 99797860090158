.maps {
  display: flex;

  .maps-section{
    h1{
      padding-bottom: 15px;
    }
    p{
      max-width: 928px;
    }
  }
}
