.chapter-indicator {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  display: block;
  margin: 0 auto 3em auto;
  padding-bottom: 3em;
  background: #f7f7f7;
  border-radius: 10px;
  border: solid 1px #f1f4ff;
  h2 {
    margin-top: 1em;
    font-size: 1.6em;
  }
}


.chapter-indicator-section {

  a {
    margin: 20px 17px;
    width: 308px auto;
    height: 62px;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-grow: 10%;
    border-radius: 3px;
    border: solid 1px #eaeaea;
    color: #006fdd;
    text-align: center;
    font-weight: 600;
    &:hover{
      text-decoration: none;
    }
  }
}

.chapter-indicator-heading.row {
  margin: 0 3em;
}


  //Desktop - from 1200px up
  @media (min-width: map-get($grid-breakpoints, xl)) {
  }

  //Up to tablet landscape - up to 1199px
  @media (max-width: map-get($grid-breakpoints, xl) - 1px) {
  }

  //Tablet landscape - from 992px to 1199px
  @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1px) {
  }

  //Up to tablet portrait - up to 991px
  @media (max-width: map-get($grid-breakpoints, lg) - 1px) {
  }

  //Tablet portrait - from 768px to 991px
  @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {
  }

  //Up to phone landscape - up to 767px
  @media (max-width: map-get($grid-breakpoints, md) - 1px) {
  }

  //Phone landscape - from  576px to 767px
  @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
  }

  //Phone portrait - up to 575px
  @media (max-width: map-get($grid-breakpoints, sm) - 1px) {
  }
