/* purgecss start ignore */
$fancybox-thumbs-height: 100px;

.fancybox-inner {
	right: 0 !important;
	bottom: $fancybox-thumbs-height !important;
}

.fancybox-thumbs {
	right: auto;
	left: 0;
	bottom: 0;
	top: auto !important;
	height: $fancybox-thumbs-height;
	width: 100% !important;
	padding: 0 !important;
	padding-top: 4px !important;
	padding-left: 4px !important;
	background: rgba(0, 0, 0, 0.3) !important;
}

.fancybox-thumbs__list a {
	height: 100px !important;
	width: 125px !important;

	&::before {
		border: 4px solid $color-white !important;
		transition: none !important;
	}
}
/* purgecss end ignore */
