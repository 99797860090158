
.city-detail{
    background: #f7f7f7;
    outline: 1px solid #eae8e8;
    padding: 1em 3em 3em 3em;
    margin-bottom: 2em;
    border-radius: 0 0 10px 10px;
    p{
      margin-bottom: 0px;
      font-size: 1em;
    }
    li {
      font-size: 0.8rem;
      img {
        //margin-left: 2em;
        width: 5em;
      }
    }
    .city-images{
      display:flex;
      flex-direction: column;
      max-width: 100%;
      align-items: flex-end;
      .city-map{
        padding-top: 54px;
      }
    }
    h1{
      padding-top: 2px;
      padding-bottom: 11px;
      color: #11347e;
      font-size: 1em;
    }
    h3 {
      font-size: 1.2em;
      color: #11347e;
    }
    h4 {
      font-size: 1em;
      margin-bottom: 2em;
      color: #11347e;
      font-weight: 500;
    }
    .city-detail-data-value p{
      color: #11347e;
    }
}

.village-of-the-year {
  margin-top: 2em;
  margin-bottom: 2em;
}

.city-basic-details {
  padding-top: 1rem;
  padding-bottom: 1em;
  p {
    font-size: 0.8rem;
    line-height: 1.7;
  }
  border-radius: 10px;
  background: #ffffff;
  outline: 1px solid #eae8e8;
}

//Phone landscape - from  576px to 767px
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
.city-detail{
  padding-top: 20px;
}
}

#cmap {
  //margin-top: 2em !important;
  //margin-bottom: 2em !important;
}

.iframe {
  margin: 2em 0;
}
//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {
  .city-detail{
    padding-top: 25px;
  }

  .city-detail-data {
    width: inherit;
    padding-right: 0px;
  }

  .city-detail-images {
    margin-top: 30px;
  }

  #cmap {
    margin-top: 15px !important;
  }

  .city-tabs .navigation-section {
    //padding-left: 15px !important;
    //padding-right: 15px !important;
    padding-top: 20px !important;
  }
}
