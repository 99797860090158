//Search result item
.search-results-item {
	margin-bottom: $baseline * 2;
}

//Search result heading
.search-result-heading {
	margin-bottom: $baseline;

	a {
		&:hover {
			text-decoration: none;
			span {
				text-decoration: underline;
			}
		}
	}

	span:not(:first-child) {
		font-size: $base-text-font-size;
		margin-left: 10px;
		color: $color-text-light;
		text-decoration: none !important;
	}
}

//Search result link
.search-result-link {
	margin-bottom: $baseline;
	display: block;
	color: $color-text;

	&:hover {
		color: $color-text;
	}
}
