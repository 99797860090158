.city-tabs{
	.navigation-section{
		min-width: 1283px;
		border-radius: 3px;
	  	background-color: $white;
		font-size: 0.9rem;
		p,a {
			font-size: 0.8rem;
			line-height: 1.7;
		}
		h3 {
			font-size: 1em;
			line-height: 1.7;
		}
		line-height: 1.7;
		div .row div {
			line-height: 1.7;
			font-size: 0.8rem;
			p {
				//font-size: 1.6em;
			}
			a {
				font-size: 1.1em;
			}
			h3 {
				font-size: 1.9em;
			}
		}
		table td, table th {
			vertical-align: middle;
		}
	}

	.nav-pills{
		width: 100%;
		//margin-left: 15px;

		.pills-tab.active {
			background: rgb(35,115,207);
			background: linear-gradient(90deg, rgba(35,115,207,1) 0%, rgba(37,125,226,1) 100%);
			color: $white;
		}

		.active .nav-link{
			background: rgb(35,115,207);
			background: linear-gradient(90deg, rgba(35,115,207,1) 0%, rgba(37,125,226,1) 100%);
			color: $white;

		}


		border-radius: 10px 10px  0 0;
		background: #f1f4ff;

		overflow: hidden;

		.nav-link{

			text-decoration: none;
			padding: 12px 2em;
			//margin-right: 18px;
			height: 48px;
			font-weight: 500;
			font-size: 1.2em;

			border-radius: 0 0 0 0;
			background-color: #f1f4ff;

			&:active{
				background: rgb(35,115,207);
				background: linear-gradient(90deg, rgba(35,115,207,1) 0%, rgba(37,125,226,1) 100%);
				color: $white;
				&:hover{
					color: $white;
				}
			}
			&:hover{
				background: rgb(35,115,207);
				background: linear-gradient(90deg, rgba(35,115,207,1) 0%, rgba(37,125,226,1) 100%);
				opacity: .8;
				color: $white;
			}
			a{
				text-decoration: none;

				&:active{
					text-decoration: none;
				}
			}
		}
	}
	.navigation-section{
		padding: 41px 42px 0px;
		margin-bottom: 10px;


		a{
			line-height: 1.75;
			a:hover{
				text-decoration: none;
			}
		}
		p{
				//color: #0c3283;
				//text-align: center;
			}
		}
	}

.navigation-labels {
	border-top: 1px solid #eae8e8;
	border-bottom: 1px solid #eae8e8;
	border-left: 1px solid #eae8e8;
	border-right: 1px solid #eae8e8;
	border-radius: 10px;
	margin-bottom: 2em;
}


// City Tabs 2




	.city-tabs-2{
		.navigation-section{
			min-width: 100%;
			border-radius: 3px;
		  border: solid 1px #f1f4ff;
		  background-color: #fbfbfb;

		}

		.nav-pills{
			width: 100%;
			margin-left: 15px;

			#pills-1-tab.active,
			#pills-2-tab.active,
			#pills-3-tab.active,
			#pills-4-tab.active,
			#pills-5-tab.active,
			#pills-6-tab.active
			{
				background-color: #11347e;
				color: $white;
			}

			.active .nav-link{
				background-color: #11347e;
				color: $white;
			}

			.nav-link{
				text-decoration: none;
				padding: 12px 30px;
				margin-right: 18px;
				height: 48px;
				font-weight: 600;
	  		border-radius: 3px;
	  		background-color: #f1f4ff;

				&:active{
					background-color: #11347e;
					color: $white;
				}
				&:hover{
					background-color: #11347e;
					color: $white;
				}
				a{
					text-decoration: none;
					&:active{
						text-decoration: none;
					}
				}
			}
		}
		.navigation-section{
			padding: 41px 42px 0px;
			padding-bottom: 20px;
			margin-bottom: 73px;

			a{
				line-height: 1.75;
				a:hover{
					text-decoration: none;
				}
			}
			p{
					color: #0c3283;
					text-align: center;
				}
			}
			table{

				tr,td{
					border-radius: 3px;
				  border: solid 1px #d7d7d7;
  				background-color: #ffffff;
					height: 48px;
					text-align: left;
				}
				td{
					padding: 5px 0px;
					padding-right: 12px;
					padding-left: 20px;
				}
			}
			.navigation-labels{
				.city-list{
					padding-top: 19px;
					padding-left: 20px;
					padding-bottom: 99px;

					a{
						&:hover{
							text-decoration: none;
						}
					}
				}
			}
		}
.navigation-labels {
	.tab-content {
		background: $white;
		border: solid 1px #f1f4ff;
		padding-bottom: 1em;
		border-radius: 0 0 10px 10px;
	}
}
.nav-item a {
	color: #11347e;
}