/* =WordPress Core
-------------------------------------------------------------- */
/* purgecss start ignore */
.alignnone {
	margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
	display: block;
	margin: $baseline * 2 auto;
}

.alignright {
	float: right;
	margin: 0 0 20px 30px;
}

.alignleft {
	float: left;
	margin: 0 30px 20px 0;
}

.alignnone {
	margin: 0 20px $baseline 0;
}

/* Text meant only for screen readers. */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar. */
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	.alignleft,
	.alignright {
		float: none;
		display: block;
		margin: 0;
		margin-bottom: $baseline;
		margin-left: auto;
		margin-right: auto;
	}
}

/* purgecss end ignore */
