.region-heading{
  h1{
    padding-bottom: 24px;
    font-size: 1.5em;
    margin-left: 1em;
  }
  p{
    text-align: right;
  }
  a{
    &:hover{
      text-decoration: none;
    }
  }
}
