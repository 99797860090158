@import "scss/abstracts/_variables.scss";
@import "scss/bootstrap/bootstrap/functions";
@import "scss/bootstrap/bootstrap/variables";

@import "~bootstrap/scss/bootstrap";
@import "scss/base/wordpress-header.scss";
//@import "scss/bootstrap/bootstrap.scss";
@import "scss/vendor/selectric.scss";
@import "scss/vendor/slick.scss";
@import "scss/overrides/bootstrap-override.scss";
@import "scss/overrides/fancybox-override.scss";
@import "scss/base/_base.scss";
@import "scss/base/_breakpoints.scss";
@import "scss/base/_kt.scss";
@import "scss/base/_typography.scss";
@import "scss/base/_utilities.scss";
@import "scss/base/_wordpress-header.scss";
@import "scss/base/_wordpress.scss";
@import "scss/base/columns-shortcode.scss";
@import "scss/layout/_main.scss";
@import "scss/components/_buttons.scss";
@import "scss/components/base-secion.scss";
@import "scss/components/map.scss";
@import "scss/components/nutsUrovenSelect.scss";
@import "scss/components/post-detail-other-section.scss";
@import "scss/components/simple-pagination.scss";
@import "scss/components/suggestions.scss";

@import "components/administrative-search-box/administrative-search-box.scss";
@import "components/advantages-item/advantages-item.scss";
@import "components/advantages-section/advantages-section.scss";
@import "components/aside/aside-nav.scss";
@import "components/baseline-grid/baseline-grid.scss";
@import "components/breadcrumbs/breadcrumbs.scss";
@import "components/chapter-indicator/chapter-indicator.scss";
@import "components/city-detail/city-detail.scss";
@import "components/city-tabs/city-tabs.scss";
@import "components/comment-form/comment-form.scss";
@import "components/commune-search-box/commune-search-box.scss";
@import "components/commune-table/commune-table.scss";
@import "components/contact-form-section/contact-form-section.scss";
@import "components/contact-form/contact-form.scss";
@import "components/contact-section/contact-section.scss";
@import "components/culture-tabs/culture-tabs.scss";
@import "components/employees-item/employees-item.scss";
@import "components/employees-section/employees-section-no-img.scss";
@import "components/entry-content/_entry-content.scss";
@import "components/error-404-section/error-404-section.scss";
@import "components/euroregion-search-box/euroregion-search-box.scss";
@import "components/footer/footer-bottom.scss";
@import "components/footer/footer-social.scss";
@import "components/footer/footer-top.scss";
@import "components/footer/footer.scss";
@import "components/header/header-main.scss";
@import "components/hp-seo-text-section/hp-seo-text-section.scss";
@import "components/hp-slider/hp-slider.scss";
@import "components/industrial-articles/industrial-articles.scss";
@import "components/industry-search-box/industry-search-box.scss";
@import "components/industry-section/industry-section.scss";
@import "components/information-RIS/information-RIS.scss";
@import "components/local-article/local-article.scss";
@import "components/local-search-box/local-search-box.scss";
@import "components/login/login.scss";
@import "components/map-download/map-download.scss";
@import "components/maps-tabs/maps-tabs.scss";
@import "components/modal.scss";
@import "components/navigation/navigation.scss";
@import "components/newsletter-section/newsletter-section.scss";
@import "components/not-found/not-found.scss";
@import "components/pagination/pagination.scss";
@import "components/partners-section/partners-section.scss";
@import "components/post-author-item/post-author-item.scss";
@import "components/post-author-section/post-author-section.scss";
@import "components/post-comments-section/post-comments-section.scss";
@import "components/post-detail-header/post-detail-header.scss";
@import "components/post-share-section/post-share-section.scss";
@import "components/posts-item/posts-item.scss";
@import "components/posts-section/posts-section.scss";
@import "components/project-articles-admin/project-articles-admin.scss";
@import "components/project-articles/project-articles.scss";
@import "components/project-filter/project-filter.scss";
@import "components/project-notices/project-notices.scss";
@import "components/region-heading/region-heading.scss";
@import "components/region-tabs/region-tabs.scss";
@import "components/related-posts-section/related-posts-section.scss";
@import "components/search-form/search-form.scss";
@import "components/search-results-item/search-results-item.scss";
@import "components/search-results-section/search-results-section.scss";
@import "components/search-section/search-section.scss";
@import "components/signpost-item/signpost-item.scss";
@import "components/signpost-section/signpost-section-full-width.scss";
@import "components/signpost-section/signpost-section-no-img.scss";
@import "components/signpost-section/signpost-section.scss";
@import "components/statistical-search-box/statistical-search-box.scss";
@import "components/statistical-table/statistical-table.scss";
@import "components/village-search-box/village-search-box.scss";
@import "components/village-tables/village-tables.scss";
@import "components/wp-gallery/wp-gallery.scss";

@import "scss/main";
