//Signpo section full-width
.signpost-section-full-width {
	.container {
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;

		& > header {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	.row {
		margin: 0;
	}

	.signpost-item {
		margin-bottom: 0;
		position: relative;
		padding: 0;

		&:hover {
			.signpost-item-img {
				&::after {
					opacity: 0;
				}

				img {
					opacity: 1;
				}
			}

			.signpost-item-title {
				color: $color-white;
				text-decoration: none;
			}
		}

		&::after {
			content: "";
			position: relative;
			display: block;
			width: 100%;
		}

		&.square {
			&::after {
				padding-bottom: 100%;
			}
		}

		&.landscape {
			&::after {
				padding-bottom: 100% / 4 * 3;
			}
		}

		&.portrait {
			&::after {
				padding-bottom: 100% / 3 * 4;
			}
		}

		.signpost-item-inner {
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 1;
			overflow: hidden;
			padding: 10px 15px;
		}

		.signpost-item-img {
			position: absolute;

			&::after {
				background: $color-black;
				opacity: 0.4;
				transition: opacity 0.2s;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				font-family: "object-fit: cover;";
			}
		}

		.signpost-item-title {
			color: $color-white;
			transform: none;
			margin-bottom: 0;
			position: relative;
			z-index: 1;
			font-size: $base-subheading-font-size;
		}

		p {
			color: $color-white;
			margin-bottom: 0;
			margin-top: $baseline;
			max-width: 450px;
			font-weight: 400;
			transform: none;
			z-index: 1;
			position: relative;
		}

		.mt-auto {
			display: none;
		}
	}

	//Phone portrait - up to 575px
	@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
		.signpost-item {
			max-width: 100%;
		}
	}
}
