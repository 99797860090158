.industrial-articles{
  display: block;
  margin: 0 auto;
}

.industrial-articles .container {
  padding: 0;
}

.industrial-articles article {
  word-wrap: break-word;
  border-radius: 3px;
  border: solid 1px #f1f4ff;
  background-color: #ffffff;
  margin: 18px 29px;
  padding: 24px 29px;
}

.industrial-articles-section h2 {
  font-family: "OpenSans", sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #313131;
  text-decoration: underline;
  padding-bottom: 14px;
  cursor: pointer;

  &:hover{
    text-decoration: none;
  }
}

.industrial-articles-section p {
  font-family: "OpenSans", sans-serif;
  font-size: 16px;
  color: #6b6b6b;
  line-height: 1.2;
  text-align: left;

}

.industrial-articles-section strong {
  line-height: 12px;
  margin-bottom: 0px;
}


//Phone landscape - from  576px to 767px
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
  .industrial-articles article {
    margin: 18px 60px;
  }
  .industrial-articles-section p {
    font-size: 14px;
  }
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
  .industrial-articles-section p {
    font-size: 13.5px;
  }
}
