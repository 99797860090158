.project-filter {
	border-radius: 3px;
	background-color: #fbfbfb;
	display: flex;
	border-bottom: 1px solid #eae8e8;
	border-left: 1px solid #eae8e8;
	border-right: 1px solid #eae8e8;

	form {
		display: flex;
		flex-wrap: wrap;
		padding: 24px 29px 18px 40px;
	}

	.btn-wrap {
		width: 100%;
		display: flex;
		padding: 1% 5%;
		a {
			margin-left: auto;
			padding: 12px 25px;
		}
		.btn {
			min-width: 139px;
			text-transform: uppercase;

			span {
				&:hover {
					color: $button-primary-text-color;
				}
			}
		}
	}

	.ml-1 {
		margin-left: 15px !important;
	}
}

.project-filter-section .container div {
	background: #f7f7f7;
}

#project-filter-form {
	.budget-period-holder{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.budget-period-from, .budget-period-to{
			width: 48%;
			display: flex;
			flex-direction: column;
		}
		input{
			width: 100%;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	#project-filter-form {
		.budget-period-holder{
			flex-direction: column;
			.budget-period-from, .budget-period-to{
				width: 100%;
			}
		}
	}
}


#project-filter-form {
	width: 100%;
}

.project-filter-item {

	padding: 1% 5%;

	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
	label {
		font-size: 1rem;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #0e2242;
	}

	input,
	.input-wrapper{
		flex-grow: 1;
		select,
		input{
			width: 100%;
		}
	}

	input,
	select {
		display: inline-block;
		font-size: 14px;
		height: 38px;
		width: 100%;
		border-radius: 3px;
		border: solid 1px #b9b9b9;
		background-color: #ffffff;
		outline: 0;
		padding-left: 12px;
		color: lighten(black, 30%);

		&::placeholder {
			color: lighten(black, 30%);
			opacity: 1;
		}
	}

	select:not([disabled]) {
		cursor: pointer;
		color:black;
		background: #f5f7fb
	}

	input {
		color:black;

	}

	&.location {
		width: 100%;
	}
}

.project-filter-item-1_1 {
	min-width: 100%;
	max-width: 100%;
}

.project-filter-item-1_3 {
	min-width: 33%;
	max-width: 33.3%;
}

.project-filter-item-1_2 {
	min-width: calc(100% / 2 - 1px);
	max-width: 50%;
}

body > main > div.header-project.container {
	padding: 0px !important;
	margin-left: 132px;
}

.header-project h1 {
	font-family: "OpenSans", sans-serif;
	font-weight: bold;
	color: #192438;
	padding-top: 30px;
	padding-bottom: 16px;
}

.city-tabs .project-filter {
	border-radius: unset;
	background-color: unset;
	border: unset;
	flex-direction: column;
}

//Up to tablet landscape - up to 1199px
@media (max-width: map-get($grid-breakpoints, xl) - 1px) {
	.project-filter {
		form {
			padding-left: 5px;
		}

		.btn-wrap {
			margin-right: 30px;
		}
	}

	.project-filter-item-1_3 {
		min-width: calc(50% - 1px) !important;
	}
}

//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
	.project-filter {
		form {
			padding-left: 30px;
		}

		.btn-wrap {
			margin-right: 30px;
		}
	}

	.project-filter-item-1_1,
	.project-filter-item-1_2,
	.project-filter-item-1_3 {
		min-width: 100% !important;
		max-width: unset;
	}
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	.project-filter {
		form {
			padding-left: 15px;
		}

		margin-bottom: 20px;
	}

	.project-filter-item {
		display: block;
		margin-bottom: 30px;

		label {
			min-width: auto !important;
			margin-bottom: 10px;
		}

		select {
			&:nth-child(3) {
				margin-top: 15px;
			}
		}
	}
}

@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
	.project-filter {
		label {
			display: block;
			text-align: left;
		}
	}
}
