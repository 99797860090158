.culture-tabs {
	.culture-footer {
		font-size: 16px;
		font-style: italic;
		line-height: 1.5;
		color: rgba(0, 0, 0, 0.5);
		padding-bottom: 64px;
		padding-top: 19px;
	}

	.city-description{
		p{
			text-align: center;
			line-height: 1.5;
	  	text-align: center;
	  	color: #0c3283;
			font-weight: 600;
			margin-bottom: 6px;
		}
	}



	.culture-section {
		width: 100%;
		a {
			display: block;
			margin-bottom: 0;
			line-height: 1.75;
  		letter-spacing: normal;
  		color: #11347e;
			&:hover {
				text-decoration: none;
			}
		}
	}

	.culture {
		padding-bottom: 142px;

		h2 {
			color: black;
			padding-top: 15px;
			padding-bottom: 15px;
		}

		.culture-section-1,
		.culture-section-2,
		.culture-section-3,
		.culture-section-4 {
			a,
			p {
				text-align: center;

				a:hover {
					text-decoration: none;
				}
			}
		}

		section {
			display: none;
			width: 100%;
			padding: 41px 42px 0px;
			border-radius: 3px;
			border: solid 1px #f1f4ff;
			background-color: #fbfbfb;
			color: $color-white;

			a {
				line-height: 2;
			}
		}

		label {
			display: inline-block;
			margin: 0 19px 0 0;
			border-radius: 3px;
			padding: 12px 25px;
			font-weight: 600;
			text-align: center;
			line-height: 1.5;
			color: #082e88;
			font-size: 16px;
			background-color: #f1f4ff;
		}

		label:before {
			font-family: "OpenSans";
			font-weight: normal;
		}
	}

	.culture label[for*="1"]:before {
		content: none;
	}

	.culture label[for*="2"]:before {
		content: none;
	}

	.culture label[for*="3"]:before {
		content: none;
	}

	.culture label[for*="4"]:before {
		content: none;
	}

	.culture label:hover {
		border-radius: 3px 3px 0 0;
		border-top: solid 1px #9fb0e3;
		border-bottom: solid 1px #9fb0e3;
		background-color: #11347e;
		color: $color-white;
		cursor: pointer;
	}

	.culture {
		input:checked + label {
			border-radius: 3px 3px 0 0;
			//border: solid 1px #e39fa6;
			border-bottom: solid 1px #9fb0e3;
			background-color: #11347e;
			color: $color-white;
		}
	}
.culture-items{
	#tab1culture:checked ~ #content1culture,
	#tab2culture:checked ~ #content2culture,
	#tab3culture:checked ~ #content3culture,
	#tab4culture:checked ~ #content4culture {
		display: block;
	}
}

	//Tablet portrait - from 768px to 991px
	@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {
		.culture label {
			margin: 0;
			padding: 10px;
			font-family: "OpenSans", sans-serif;
			font-size: 18px;
		}
	}
	//Phone landscape - from  576px to 767px
	@media (max-width: map-get($grid-breakpoints, md) - 1px) {
		.culture label {
			padding: 8px 10px;
			font-size: 16px;
			border-radius: 3px !important;
			transform: translateY(3px);
		}

		.culture {
			padding-top: 20px;
			//margin-left: 75px;
			//margin-right: 75px;
		}
	}
	//Phone portrait - up to 575px
	@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
		.culture {
			//padding-top: 100px;
		}

		.culture section {
			padding-left: 20px;
		}
	}
}
