// jQuery UI AutoComplete CSS
.ui-autocomplete {
	position: absolute;
	//margin-left: 15px;
	display: inline-block;
	border: 1px solid silver;
}
.ui-menu .ui-menu-item {
	background-color: white;
	cursor: pointer;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 8px;
	padding-right: 8px;
	width: auto;

	&:hover {
		background-color: #dcdcdc;
	}
}

.ui-helper-hidden-accessible {
	display:none;
}