.navigation {
	display: block;
	//max-width: 84%;
	margin: 0 auto;

	.content-hidden{
		visibility: hidden;
	}
	.text-block {
		display: flex;
		flex-wrap: wrap;
		gap: 0em 2.3em;
		a {
			padding-bottom: 0;
			margin-bottom: 5px;
		}
		a.active-link{
			text-decoration: underline;
		}
	}
	.text-block a::after {
		content: " ";
		word-spacing: 1em;
		&:not(:last-child):after {
			content: ' |'
		}
	}

	.active-link {
		text-decoration: none;
	}
}

.text-block a {
	font-family: "OpenSans", sans-serif;
	font-size: 16px;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.89px;
	color: #ffffff;
	padding: 0px 25px 20px 20px;
	text-decoration: none;
}

.text-block a:hover {
	text-decoration: underline;
}


.text-block-heading {
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.8px;
	color: #ffffff;
	padding-left: 5px;
}


// Tab Navigation

.navigation-items {
	margin: auto;
	//background: #f5f5f5;
	border-top-right-radius: 10px;
}

.navigation section {
	display: none;
	padding: 10px 20px 5px;
	border-radius: 0px 3px 3px 3px;
	//border: solid 1px #e39fa6;
	border-top: solid 1px #ba1828;
	background-color: #ba1828;
	color: $color-white;
	a {
		line-height: 2;
	}
}

main input {
	display: none;
}

.navigation label {
	display: inline-block;
	margin: 0 0 0px;
	padding: 15px 25px;
	font-weight: 500;
	text-align: center;
	border: 1px solid transparent;
	font-size: 1.3em;
	color: #00519d;
}

.navigation label:before {
	font-family: "OpenSans";
	font-weight: normal;
	margin-right: 10px;
}



.navigation label:hover {
	border-radius: 10px 10px 0 0;
	//border: solid 1px #e39fa6;
	border-top: solid 1px #ba1828;
	border-bottom: solid 1px #ba1828;
	background-color: #ba1828;
	color: $color-white;
	cursor: pointer;
}


	.navigation input:checked + label {
		border-radius: 10px 10px 0px 0px;
		//border: solid 1px #e39fa6;
		border-bottom: solid 1px #ba1828;
		background-color: #ba1828;
		color: $color-white;
	}



#tab1:checked ~ #content1,
#tab2:checked ~ #content2,
#tab3:checked ~ #content3,
#tab4:checked ~ #content4 {
	display: block;
}

//Tablet portrait - from 768px to 991px
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {
	.navigation label {
		margin: 0px;
		padding: 10px 10px;
		font-family: "OpenSans", sans-serif;
		font-size: 18px;
	}
	.text-block {
		padding-left: 50px;
	}
}

//Phone landscape - from  576px to 767px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {
	.navigation label {
		padding: 8px 10px;
		font-size: 16px;
		border-radius: 3px !important;
		transform: translateY(3px);
	}
	.text-block-heading {
		padding-left: 2px;
	}
	.text-block {
		padding-left: 50px;
	}
	.text-block a {
		font-size: 14px;
		padding-right: 10px;
	}
	.navigation {
		padding-top: 20px;
		//margin-left: 75px;
		//margin-right: 75px;
	}
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	.text-block a {
		font-size: 14px;
		padding-right: 10px;
	}

	.navigation {
		padding-top: 0px;
	}
	.navigation section {
		padding-left: 20px;
	}

	.text-block {
		padding-left: 20px;
	}
}
