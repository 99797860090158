/* purgecss start ignore */

.employees-section-no-img {

	.employees-item-inner {
		background: $color-white;
		padding: $baseline 15px;
		box-shadow: 0px 5px 30px 0 rgba(39, 44, 78, 0.01);
	}

	.employee-img {
		display: none;
	}

}

/* purgecss end ignore */