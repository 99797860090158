//Contact form
.contact-form {
	background: $contact-form-bg-color;
	box-shadow: 0 0 0 1px $contact-form-border-color;
	position: relative;
	padding: $baseline 40px;
	padding-top: $baseline * 2;
	margin-bottom: $baseline;
	overflow: hidden;

	.contact-form-top input {
		background: $contact-form-input-bg-color;
		box-shadow: inset 0 0 0 1px $contact-form-border-color;
		color: $contact-form-text-color;
		font-size: $base-text-font-size;
		margin-bottom: $baseline * 2;
		line-height: $baseline * 2;
		height: $baseline * 2;
		outline: none;
		width: 100%;
		border: none;
		border-radius: 0;
		padding-left: 16px;

		&::placeholder {
			color: $contact-form-text-color;
		}
	}

	textarea {
		font-size: $base-text-font-size;
		background: $contact-form-input-bg-color;
		box-shadow: inset 0 0 0 1px $contact-form-border-color;
		color: $contact-form-text-color;

		outline: none;
		width: 100%;
		padding: 0 16px;
		padding-top: $baseline * 0.8;
		resize: none;
		height: $baseline * 10;
		margin-bottom: $baseline;
		line-height: $baseline;
		border: none;
		vertical-align: top;

		&::placeholder {
			color: $contact-form-text-color;
		}
	}

	.btn {
		margin-left: auto;
		min-width: 152px;
	}

	.consent-notice {
		margin-right: 20px;
		font-weight: 400;
		color: $contact-form-text-color;

		a {
			color: $link-text-color;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	.validator {
		bottom: $baseline * 2;
	}

	textarea + .validator {
		bottom: $baseline;
		left: 0;
	}

	.disclaimer-notice {
		font-size: $small-text-font-size;
		display: block;
		margin-bottom: $baseline * 2;
		margin-top: $baseline * -1;
		transform: translateY(6px);
		text-align: center;
	}

	.file-input {
		display: flex;
		align-items: center;

		label {
			min-width: 0;
			margin-left: 0;
			width: 200px;
			margin-right: 10px;
			flex-shrink: 0;
		}

		input {
			display: none;
		}
	}

	.fake-label {
		font-size: $base-text-font-size;
		font-weight: 700;
		color: $color-secondary;
		margin-right: 30px;
	}

	.file-info {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.fake-input {
		display: flex;
		align-items: center;
	}
}

.contact-form-career {
	.contact-form-top {
		& > div {
			width: 30%;
		}
	}
}

//Contact form top
.contact-form-top {
	display: flex;
	justify-content: space-between;

	& > div {
		width: 30%;
		position: relative;
	}
}

//Contact form center
.contact-form-center {
	display: flex;
	justify-content: space-between;
	margin-bottom: $baseline * 2;

	& > div {
		width: 46%;
	}
}

//Contact form bottom
.contact-form-bottom {
	display: flex;
	align-items: center;
}

//Contact form bg
.contact-form-bg {
	height: $baseline * 13.5;
	//z-index: 1;
	position: absolute;
	top: -$baseline * 2.5;
	right: -$baseline * 3;
}

.contact-form-bottom-bg {
	position: absolute;
	right: -$baseline * 3.2;
	top: 50%;
	transform: translateY(-50%);
	height: $baseline * 2.2;
}

//Entry content
.entry-content .contact-form {
	margin-top: $baseline * 2;
	margin-bottom: $baseline * 2;
	padding: $baseline * 2;

	.fake-label {
		margin-right: 15px;
	}

	.file-input {
		label {
			width: auto;
		}
	}

	.contact-form-bottom-bg {
		display: none;
	}

	li {
		margin-bottom: 0;
		&::before {
			display: none !important;
		}
	}
}

//Up to phone landscape - up to 767px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {
	//Contact form
	.contact-form {
		.btn {
			margin-left: 0;
		}
		.consent-notice {
			margin-bottom: $baseline;
			margin-right: 0;
			width: 100%;
		}
	}

	//Contact form top
	.contact-form-top {
		flex-wrap: wrap;
		& > div {
			width: 48%;

			&:last-child {
				width: 100%;
			}
		}
	}

	.contact-form-bottom {
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;
	}
}

// phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	.contact-form {
		padding: $baseline 15px !important;

		.contact-form-top input {
			margin-bottom: $baseline;
		}

		textarea {
			margin-bottom: $baseline;

			height: $baseline * 7;
		}

		.validator {
			bottom: $baseline;
		}

		.file-input {
			flex-wrap: wrap;
			text-align: center;
			margin-bottom: $baseline * 0.5;

			.fake-label {
				width: 100%;
				margin-bottom: $baseline * 0.5;
			}

			label {
				margin-right: 0;
				width: 200px;
			}

			.fake-input {
				flex-direction: column;
			}
		}
	}

	.contact-form-top {
		flex-wrap: wrap;
		margin-bottom: $baseline;

		& > div {
			width: 100%;
		}
	}
}
