//Footer top

.footer-top {
	background: $footer-top-bg-color;
	color: $footer-top-text-color;
	line-height: 1.9em;

	.container {
		padding-top: $baseline;
		position: relative;

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 30px;
			width: calc(100% - 60px);
			height: 1px;
			background: $footer-top-border-color;
		}
	}
}
//Footer block

.footer-block {
	margin-bottom: 1.8em;
	//Article heading
	.article-heading {
		margin-bottom: $baseline/4;
	}

	.footer-block ~ h2,
	p {
		user-select: none;
		font-size: 1.15em;
	}

	h2 {
		user-select: none;
		font-size: 1.15em;
		font-weight: normal;
		margin-top: 0.2em;
	}

	.table-responsive {
		box-shadow: none;
		margin-top: 0;
		margin-bottom: 0;
	}

	table {
		td,
		th {
			padding: 0 10px 0 0;

			&:first-child {
				padding-right: 0;
			}

			&::after,
			&::before {
				display: none;
			}
		}
	}
}
//Footer top nav

.footer-top-nav {
	li {
		font-size: $footer-top-font-size;
		transform: translateY($footer-top-text-offset);
		margin-bottom: $baseline;
	}

	a {
		color: $footer-top-nav-text-color;
		font-weight: $footer-top-nav-fotn-weight;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
}
//Table
//Mail Block

.mail-block {
	display: block;
	width: 19em;
	height: 40px;
	background-color: #ffffff;
	border: solid 1px #ffffff;
	border-radius: 10px;
	text-decoration: none;
	margin-top: 2.8em;
	padding-top: 1px;

	&:hover {
		span {
			text-decoration: underline;
		}
	}
}

.mail-block span {
	text-decoration: none;
	font-family: $font-family-primary;
	font-size: 16px;
	font-weight: normal;
	text-align: left;
}
.mail-block span p {
	color: #257bde;
	margin-left: 2.9em;
	position: relative;
	top: -2px;
}

.mail-block p {
	user-select: none;
}

.mail-block-item {
	display: block;
	width: 37px;
	height: 38px;
	background: url(/build/images/new/envelope.png) center no-repeat;
	background-size: 2.2em 1.5em;
	float: left;
	text-align: left;
	margin-left: 1em;
}


.entry-content .mmre {
	box-sizing: border-box;
	width: 240px;
	height: auto;
	padding: 0 0 18px;
}

.entry-content .msm {
	box-sizing: border-box;
	width: 176px;
	height: auto;
	padding: 0 0 18px 10px;
}

.footer-block p {
	color: $color-white;
}

.table-line-responsive {
	display: table;
	border-radius: 3px;
}

.table-responsive{
	display: block;
	border: none;
	font-size: 0.9em;
}

svg:not(.trumbowyg svg) {
	margin: 9px;
}

.entry-content tbody td {
	font-weight: normal;
	font-size: 14px;
}

.entry-content-item a {
	text-decoration: underline;
	color: #fff;

	&:hover {
		text-decoration: none;
	}
}
// copyright

.copyright {
	opacity: 0.5;
	font-family: "Open Sans", sans-serif;
	font-size: 1em;
	font-weight: 100;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 1px;
	text-align: right;
	color: #ffffff;
	//padding-bottom: 32px;
}

.mmr-logos-block {
	align-self: end;
}

.mmr-logos {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	gap: 3em;
	padding-bottom:5em;
}

.mmr-logos a img {
	height: 61px;
}
//Footer brand

.footer-brand {
	display: block;
	margin-top: $footer-brand-margin-top;
	text-decoration: none;
	margin-bottom: $baseline * 1;
	text-align: center;

	&:active,
	&:focus,
	&:hover {
		text-decoration: none;
	}

	span {
		font-size: $footer-brand-font-size;
		transform: translateY($footer-brand-text-offset);
		color: $footer-brand-text-color;
		display: block;
		text-align: center;
		margin-top: $baseline;
	}
}
//Desktop - from 1200px up
@media (min-width: map-get($grid-breakpoints, xl)) {
}
//Up to tablet landscape - up to 1199px
@media (max-width: map-get($grid-breakpoints, xl) - 1px) {
}
//Tablet landscape - from 992px to 1199px
@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1px) {
}
//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
}
//Tablet portrait - from 768px to 991px
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {
}
//Up to phone landscape - up to 767px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {
	//Footer block
	.footer-block {
		text-align: center;
	}
	// Centering of images and mail block
	.entry-content img {
		margin: auto auto 20px;
		width: 50%;
	}

	.mail-block {
		margin: auto auto 20px;
		width: 70%;
	}
}
//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	//Footer block
	.mail-block {
		margin: auto auto 20px;
		width: 60%;
	}

	.entry-content img {
		margin: auto auto 20px;
		width: 60%;
	}

	.footer-block {
		text-align: center;

		table {
			display: block;

			* {
				display: block;
			}

			td,
			th {
				text-align: center;
				padding-top: 0;
				padding-bottom: 0;
			}

			tr {
				padding: $baseline * 0.5 0;
			}
		}
	}
}
//Phone landscape - from  576px to 767px
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
}
