//Contact section
.contact-section {
	.entry-content {
		h2 {
			margin-top: 0;
		}

		h3 {
			margin-bottom: $baseline;
		}

		img {
			border: 0;
			box-shadow: none;
		}

		.table-responsive {
			box-shadow: none;
			margin-top: 0;
			margin-bottom: $baseline;
		}

		th,
		td {
			padding: 0;
			padding-right: 10px;
			padding-bottom: 12px;
			padding-top: 0;

			&:first-child {
				padding-right: 0;
			}

			&::before,
			&::after {
				display: none;
			}
		}
	}
}

//Contact map
.contact-map {
	position: relative;
	display: block;
	width: 100%;
	padding: 5px;
	max-width: 400px;
	border: 1px solid $color-bg-01;
	background: $color-white;

	img {
		display: inline-block;
		border-radius: 0;
	}
}

//Contact item
.contact-item {
	display: flex;
	font-size: $base-text-font-size;
	margin-bottom: $baseline;

	img {
		height: $baseline;
		width: $baseline;
		margin-right: 12px;
	}
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	//Contact section
	.contact-section {
		.entry-content {
			text-align: center;

			h2 {
				margin-bottom: $baseline;
			}

			table {
				max-width: 350px;
				margin-left: auto;
				margin-right: auto;

				td {
					text-align: center;
					padding-right: 0;
				}
			}
		}
	}

	//Contact item
	.contact-item {
		display: flex;
		font-size: $base-text-font-size;
		margin-bottom: $baseline;
		justify-content: center;

		img {
			height: $baseline;
			margin-right: 12px;
		}
	}

	//Contact map
	.contact-map {
		margin-left: auto;
		margin-right: auto;
	}
}
