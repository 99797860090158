.local-articles{
  display: block;
  margin: 0 auto;
}

.local-articles .container {
  padding: 0;
}

.local-articles article {
  word-wrap: break-word;
  background: #f7f7f7;
  border-radius: 10px;
  border: solid 1px #f1f4ff;
  margin: 0 auto 3em auto;
  padding: 2em 2em 2em 2em;
}

.local-articles-section h2 {
  font-family: "OpenSans", sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #313131;
  padding-bottom: 14px;
  width: 50%;
}

.local-articles-section a.h2 {
  text-decoration: none;
  &.h2 {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
}

.local-articles-section p {
  font-family: "OpenSans", sans-serif;
  font-size: 16px;
  color: #6b6b6b;
  text-align: left;
  margin-bottom: 0px;
}


//Phone landscape - from  576px to 767px
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
  .local-articles article {
    margin: 18px 60px;
  }
  .local-articles-section p {
    font-size: 14px;
  }
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
  .local-articles-section p {
    font-size: 13.5px;
  }
}
