/* purgecss start ignore */
//Entry content
.entry-content {
	&::after {
		content: "";
		clear: both;
		display: table;
	}

	//Headings
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: $baseline;
		margin-bottom: $baseline;
	}

	h1 {
		margin-top: 0;
	}

	& > h2:first-child,
	& > h3:first-child {
		margin-top: 0;
	}

	h4,
	h5,
	h6 {
		font-size: $base-text-font-size;
		transform: translateY($base-text-offset);
	}

	//Lists
	ul,
	ol {
		list-style: none !important;
		padding: 0;
		margin: 0;
		font-size: 18px;
		margin-bottom: 1em;
		color: $color-text-light;

		li {
			margin-bottom: $baseline;
			transform: translateY(5px);
		}
	}

	//ul
	ul {
padding-left: 20px;
		li {
			padding-left: 8.2px;
			list-style-image: url('../../images/ico/triangle.svg');

		}
	}

	//ol
	ol {
		li {
			position: relative;
			counter-increment: item;
			padding-left: 20px;

			&::before {
				content: counter(item) ")";
				color: $list-bullet-color;
				font-weight: 700;
				font-size: 18px;
				top: 1px;
				width: 20px;
				text-align: right;
				display: inline-block;
				position: relative;
				margin-right: 10px;

				margin-left: -22px;
			}
		}

		&[style*="list-style-type: lower-alpha"] {
			li {
				&::before {
					content: counter(item, lower-alpha) ")";
				}
			}
		}

		&[style*="list-style-type: upper-alpha"] {
			li {
				&::before {
					content: counter(item, upper-alpha) ")";
				}
			}
		}

		&[style*="list-style-type: lower-latin"] {
			li {
				&::before {
					content: counter(item, lower-latin) ")";
				}
			}
		}

		&[style*="list-style-type: upper-latin"] {
			li {
				&::before {
					content: counter(item, upper-latin) ")";
				}
			}
		}

		&[style*="list-style-type: lower-greek"] {
			li {
				&::before {
					content: counter(item, lower-greek) ")";
				}
			}
		}

		&[style*="list-style-type: upper-greek"] {
			li {
				&::before {
					content: counter(item, upper-greek) ")";
				}
			}
		}

		&[style*="list-style-type: lower-roman"] {
			li {
				&::before {
					content: counter(item, lower-roman) ".";
				}
			}
		}

		&[style*="list-style-type: upper-roman"] {
			li {
				&::before {
					content: counter(item, upper-roman) ".";
				}
			}
		}
	}

	//Img
	img {
		max-width: 100%;
		border-radius: $image-border-radius;
	}

	//Table
	.table-responsive {
		margin: $baseline * 2 0;
		box-shadow: inset 0 0 0 1px $table-head-bg-color;
		border-radius: $table-border-radius;
	}

	//Table
		.table-responsive {
			margin: $baseline * 2 0;
			border-radius: $table-border-radius;
			border: solid 1px #d7d7d7;
		}

		table {
			margin-bottom: 0;
			width: 100%;
			max-width: 100%;

			// Table Styling
			tr {
				border-bottom: solid 1px #d7d7d7;

				&:nth-child(2n + 0) {
					background-color: $color-bg-02;
				}
			}

			td,
			th {
				font-size: $table-font-size;
				padding: $table-cell-padding;
				color: $color-text-light;

				text-align: center;
				position: relative;

				border: solid 1px #d7d7d7;
			}

			p {
				margin-bottom: 0;
				font-size: $table-font-size;
				transform: none;
			}
		}

		thead {
			td,
			th {
				font-weight: 700;
				background: $color-secondary;
				color: $color-white;
				text-align: center;

				&:first-child {
					border-top-left-radius: $table-border-radius;
				}

				&:last-child {
					border-top-right-radius: $table-border-radius;
				}

				&::after {
					display: none;
				}

				&::before {
					height: calc(100% - 14px);
					top: 14px;
				}
			}
		}

		tbody,
		tfoot {
			td,
			th {
				font-weight: 400;
			}
		}

		tbody:last-child,
		tfoot {
			tr:last-child {
				border-bottom: none;
			}
		}

	//WP caption
	.wp-caption {
		border: 0;
		padding: 0;
		text-align: center;
		max-width: 100%;

		img {
			border-radius: $image-border-radius $image-border-radius 0 0;
		}
	}

	.wp-caption-text {
		font-size: $wp-caption-font-size;
		color: $wp-caption-text-color;
		border-radius: 0 0 $image-border-radius $image-border-radius;
		background: $wp-caption-bg-color;

		transform: none;
		text-align: center;
		line-height: $baseline;
		padding: 4px 10px;
	}

	//HR
	hr {
		border: none;
		background: $hr-bg-color;

		height: 1px;
		margin: $baseline * 2 0;
		transform: translateY($base-text-offset);
	}

	//Block
	blockquote {
		font-size: $blockquote-font-size;
		background: $blockquote-bg-color;
		border-radius: $blockquote-border-radius;

		font-weight: 400;
		padding: $baseline;
		margin: $baseline * 2 0;
	}

	//Embedded video
	.embed-responsive-16by9 {
		margin-bottom: $baseline * 2;
	}

	//Empty tags fix
	strong:empty,
	b:empty,
	p:empty {
		display: none;
	}
}

.entry-content.text-center {
	* {
		text-align: left !important;
	}
}

.col-lg-9 .entry-content {
	padding-bottom: $baseline * 2;
	& > *:last-child {
		margin-bottom: 0;
	}
}

//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
	.entry-content {
		td,
		th {
			white-space: nowrap;
		}
	}
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	.entry-content {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-bottom: $baseline !important;
		}

		h2,
		h3,
		h4,
		h5,
		h6 {
			font-weight: 400;
		}
	}
}

/* purgecss end ignore */
