//HP seo text section
.hp-seo-text-section.entry-content {
	& > img {
		margin-bottom: $baseline;
	}

	h2 {
		margin-top: 0;
	}

	&.text-center {
		img {
			display: inline-block;
		}
	}
}

//HP posts listing
.hp-posts-listing {
	.posts-item {
		p {
			font-size: $small-text-font-size;
			transform: translateY($small-text-offset);
		}
	}
	.posts-item-img {
		width: 150px;
	}

	.posts-item-heading {
		font-size: $base-text-font-size;
		transform: translateY($base-text-offset);
		margin-bottom: 0;
	}
}

//HP posts listing show more
.hp-posts-listing-show-more {
	display: flex;
	color: $color-text;
	display: flex;
	align-items: center;
	font-weight: 700;
	color: $color-primary;

	img {
		height: 14px;
		width: 8px;
		transform: rotate(90deg);
		margin-left: 12px;
	}
}

//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
	//HP seo text section
	.hp-seo-text-section {
		text-align: center;
	}
}
