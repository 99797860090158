//Aside nav

.aside-container{
	padding: 1em 3em;
	background: #fff;
	border: 1px solid #EEE;

	h1{
		padding-top: 1em;
		padding-bottom: 1em;
		color: #11347e;
		font-weight: 500;
	}
	img{
		padding-bottom: 81px;
	}
	a, p, ul, li {
		color: #000;
	}
	color: #000;
}

.aside-search-box{
	text-align: right;
	label{
	  font-style: italic;
	  text-align: right;
	  color: #11347e;
		padding-right: 17px;
	}
	select {
		min-width: 217px;
		height: 38px;
		padding: 9px 18px 10px;
		width: 309px;
	  height: 38px;
	  border-radius: 3px;
	  border: solid 1px #b9b9b9;
	  background-color: #ffffff;
		outline: 0;
		color: rgba(119, 134, 153, 0.75);
		font-style: italic;
		font-size: 14px;
	}

}

.aside-nav {
	display: block;
	border: 1px solid #EEE;
	box-shadow: 0px 0px 16px 1px #eee;
	padding: 0 0;
	margin-right: 27px;

	nav {

		background: $aside-nav-bg-color;
		border-radius: 3px;
		//width: 309px;
		background-color: #fafbff;

	}

	a {
		font-size: $aside-nav-font-size;
		font-weight: 500;
		line-height: 1.2;
		font-size: 1.2em;
		color: #11347e;
		display: block;
		position: relative;
		text-decoration: none;
		z-index: 1;
		text-align: left;
		padding: .8em .5em .8em 1.5em;

		&:hover {
			text-decoration: none;
		}
	}

	.aside-submenu1 {
		overflow: hidden;
		max-height: 0;
		-webkit-transition: all 0.5s ease-in;
	}
	ul li {
		border-radius: 3px;
		background-color: #fafbff;

	}

	ul li:hover {
		box-shadow: 0px 0px 16px 1px #eee;
		a {
			color: #FFF;
		}
		background: #2479db;
	}
	ul li:hover ul li:hover {
		a {
			color: #FFF;
		}
		background: #2479db;
		opacity: .85;
	}

	li:hover ul li:hover ul li:hover {
		a {
			color: #FFF;
		}
		background: #2479db;
		color: #FFF;
	}

	li:hover .aside-submenu1, .current .aside-submenu1, .current_ancestor .aside-submenu1 {
		display: block;
		max-height: 800px;
		background-color: #f1f4ff;

		a{
		  font-weight: normal;
		  line-height: 1.5;
		  color: #11347e;
			padding-left: 2em;
		}

		.aside-submenu2 {
			overflow: hidden;
			max-height: 0;
			-webkit-transition: all 0.5s ease-in;
		}

		li:hover .aside-submenu2, .current .aside-submenu2, .current_ancestor .aside-submenu2 {
			display: block;
			max-height: 1000px;
			background-color: #fafbff;
			a{
				font-weight: normal;
				line-height: 1.5;
				padding-left: 3em;
				color: #021260;
				font-size: 1.1em;
			}
		}

	}


	/*
  we now overwrite the background-color for .submenu links only.
  CSS reads down the page, so code at the bottom will overwrite the code at the top.
*/

}
