.commune-table {
  display: flex;
  flex-direction: column;
  padding-bottom: 308px;

  .commune-table-counter{
    text-align: right;
    font-size: 14px;
  font-style: italic;
  color: #6b6b6b;
  margin-bottom: 12px;
  }



    .commune-table-year {
      border-right: solid 1px #d7d7d7;
      padding: 12px 20px;
      width: 269px;
      text-align: left;
    }


  .commune-table-table-2 {

    .commune-table-table-year {
      border-right: solid 1px #d7d7d7;
      padding: 12px 20px;
      width: 223px;
      text-align: left;
    }
    .table-label {
      width: 202px;
    }
  }





  table {
    width: 100%;
    td {
      border: solid 1px #d7d7d7;
    }

    thead {
      border-radius: 3px 3px 0px 0px;
      border: solid 1px #9fb0e3;
      background-color: #11347e;

      th {
        border-right: solid 1px #ffffff;
        color: $white;
        font-weight: 600;
        padding: 12px 20px;
        width: 203px;
        text-align: center;

        &:last-child{
          border-right: solid 1px #9fb0e3;
        }
      }
    }
    tbody {
      border: solid 1px #d7d7d7;
      background-color: #ffffff;
      text-align: center;

      th {
        padding: 12px 20px;
        text-align: center;
      }
    }
  }

  table th:first-child {
    background-color: #11347e;
  }

  table td:first-child {
    background-color: white;
  }
}
