//Error 404 section
.error-404-section {
	text-align: center;
	header {
		p {
			max-width: 500px !important;
			font-weight: 700;
		}
	}
}

//Error 404 img
.error-404-img {
	height: $baseline * 5;
	margin-left: auto;
	margin-right: auto;
}

//Up to phone landscape - up to 767px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {
	//Error 404 img
	.error-404-img {
		height: $baseline * 3;
	}
}
