/* purgecss start ignore */
// --- COOKIES STATEMENT ------------------
#ktCookieStatement {
	position: fixed;
	left: 0;
	bottom: 0;
	padding: 5px;
	width: 100%;
	font-size: 0.75em;
	text-align: center;
	color: #efefef;
	background: rgba(0, 0, 0, 0.75);
	z-index: 1000;
	padding: 5px;
}
#ktCookieStatement a {
	color: #efefef;
}
#ktCookieStatementText,
#ktCookieStatementConfirm,
#ktCookieStatementMoreInfo {
	margin: 0 5px;
}
#ktCookieStatementConfirm,
#ktCookieStatementMoreInfo {
	cursor: pointer;
	padding: 3px 5px;
	background: rgba(0, 0, 0, 0.75);
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

// --- VALIDATORS STYLE ------------------
div.validator {
	height: 0;
	position: relative;
	> span.erorr-s {
		position: absolute;
		padding: 2px 6px;
		padding-bottom: 0;
		display: block;
		left: 0;
		top: 0;
		white-space: nowrap;
		border-radius: 3px;
		color: #fff;
		font-family: verdana;
		letter-spacing: -1px;
		font-size: 12px;
		background: #c92a2a;
		z-index: 98;
		&:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			position: absolute;
			top: -5px;
			left: 10px;
			border-style: solid;
			border-width: 0 5px 10px 5px;
			border-color: transparent transparent #c92a2a transparent;
		}
	}
}
// --- AJAX LOADING ------------------
.loading {
	position: relative;

	article {
		opacity: 0.6;
	}

	&::before {
		content: "";
		background-color: transparent;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 100;
		display: block;
		height: 50px;
		width: 50px;
		border-radius: 50%;
		animation: rotate 2s infinite linear;
		margin-top: -15px;
		margin-left: -15px;
		//background-image: url("images/ico/spinner.svg");
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
/* purgecss end ignore */
