.breadcrumbs {
	font-weight: $breadcrumbs-font-weight;
	font-size: $breadcrumbs-font-size;
	//background: #f4f7f6;
	padding: 11px 12px;
	border-radius: 3px;
	//margin-top: $baseline;
	//margin-bottom: $baseline * 2;
	margin-top: 15px;
	margin-bottom: 52px;
	display: inline-block;
	a {
		position: relative;
		color: #11347e;
		margin-right: 10px;
		transition: color 0.3s ease;
		&:hover {
			color: #11347e;
			text-decoration: none;
		}
	}



	//Breadcrumbs home
	& > span > span > a:first-child {
		display: inline-block;
		color: transparent;
		width: $baseline * 0.7;
		height: $baseline;
		position: relative;
		background-image: url("../../images/ico/house.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: 16px;
		user-select: none;
	}
}

.breadcrumb-char span:nth-child(2n){
	&::before {
		content: "/";
		top: 50%;
		transform: translateY(-50%);
		padding-left: -10px;
		padding-right: 10px;
		width: 1px;
		height: 12px;
		text-decoration: none;
		border-radius: $breadcrumbs-bullet-border-radius;
		}
	}

.breadcrumb-last {
	font-weight: normal;
	color: #072148;
	&::before {
		content: "/";
		top: 50%;
		transform: translateY(-50%);
		padding-left: -10px;
		padding-right: 10px;
		width: 1px;
		height: 12px;


		border-radius: $breadcrumbs-bullet-border-radius;
	}
}

// phone portrait - up to 768px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {
	.breadcrumbs, .breadcrumbs_right {
		margin-top: 0;
		margin-bottom: 0;
		display: none;
	}
}
