.col-lg-2 {
	svg {
		display: inline-block;
		margin: 0px;
		margin-right: 9px;
	}
}

div .edit p {
	color: #2372cf;
	font-size: 16px;
	font-weight: bold;
	text-align: center !important;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
	margin: 0px;
	margin-left: auto;
	margin-right: auto;
}

div .delete p {
	color: #c12f3e;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}

.project-articles {
	padding: 0;
}

.project-articles article {
	word-wrap: break-word;
	border-radius: 3px;
	border: solid 1px #f1f4ff;
	background-color: #ffffff;
}

.project-articles-section h2 {
	font-family: "OpenSans", sans-serif;
	font-size: 15px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #313131;
	text-decoration: underline;
	padding-bottom: 14px;
	cursor: pointer;
}

.project-articles-section p {
	font-family: "OpenSans", sans-serif;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #6b6b6b;
	line-height: 1.2;
}

.project-articles-section strong {
	line-height: 12px;
	margin-bottom: 0px;
}

.project-rows p {
	line-height: 24px;
	margin-bottom: 0px;
}

//MODAL 1
/* The Modal (background) */

//MODAL 2

.modal2 {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallbck color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

// Modal Content/Box
.modal-page2 {
	border-radius: 3px;
	border: solid 1px #f1f4ff;
	background-color: #ffffff;
	margin: 15% auto; /* 15% from the top and centered */

	width: calc(100% - 30px);
	max-width: 853px;
}

.modal-page2 .modal-content {
	padding-left: 30px;
	padding-right: 0px;
	padding-top: 66px;
	padding-bottom: 15px;
	padding-right: 46px;
}

.modal-content2 {
	padding: 16px 59px 27px 59px;
}

// The Close Button
span.close {
	color: #b9b9b9;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

.project-rows {
	text-align: center;
}

.project-rows-container .btn {
}

#myModal2 {
	.btn {
		min-width: 139px !important;
	}
}

#myModal2 button {
	margin: 0 auto;
	margin-top: 40px;
	margin-bottom: 17px;
}

#myModal2 > div h2 {
	font-size: 16px;
	font-weight: bold;
	line-height: 2.63;
	color: #6a6b6b;
	text-decoration: none;
}

#description {
	height: 120px;
	margin-bottom: 39px;
	vertical-align: top;
	border: solid 1px #b9b9b9;
	width: 100%;
	border-radius: 3px;
	padding: 8px 14px 8px 14px;
	resize: none;

	&:focus {
		outline: 0;
	}
}

.project-articles input {
	display: inline;
	width: 100%;
	border-radius: 3px;
	border: solid 1px #b9b9b9;
	background-color: #ffffff;
	padding: 8px 14px 8px 14px;
	margin-bottom: 4px;

	&:focus {
		outline: 0;
	}
}

.textfield-titles {
	padding-bottom: 40px;
}

#myModal2 > div > div > div.project-rows.container > div > a {
	margin-bottom: 16px;
}

.close-button {
	text-decoration: underline;
	font-size: 14px;
	color: #0070d6;
	text-transform: uppercase;
	font-weight: 700;

	&:hover {
		text-decoration: none;
	}
}

.close-button a:hover {
	text-decoration: none;
}

#myModal2 p {
	padding: 8px 0px 0px 14px;
	margin-bottom: 6px;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: right;
	color: #6b6b6b;
}

//Desktop - from 1200px up
@media (min-width: map-get($grid-breakpoints, xl)) {
}

//Up to tablet landscape - up to 1199px
@media (max-width: map-get($grid-breakpoints, xl) - 1px) {
}

//Tablet landscape - from 992px to 1199px
@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1px) {
}

//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
	div .edit p {
		text-align: right !important;
		margin-bottom: 15px;
	}

	div .delete p {
		text-align: right;
		margin-bottom: 20px;
	}
}

//Tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
	.modal-content {
		padding-left: 20px !important;
		padding-right: 20px !important;
		padding-bottom: 20px !important;

		.container {
			padding: 0 !important;
		}
	}
}

//Up to phone landscape - up to 767px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {
}

//Phone landscape - from  576px to 767px
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
	.project-articles article {
		//margin: 18px 60px;
	}
	.project-articles-section p {
		font-size: 14px;
	}
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	.project-articles-section p {
		font-size: 13.5px;
	}
	#myModal2 p {
		text-align: left;
		padding-left: 0;
		margin-bottom: 15px;
	}
}
