//Base section
.base-section {
	.container {
		padding-top: $baseline * 3;
		padding-bottom: $baseline * 3;
	}

	.container > header {
		text-align: center;
		margin-bottom: $baseline * 3;

		.base-heading:last-child {
			margin-bottom: $baseline * 3;
		}

		.base-heading {
			margin-bottom: $baseline;
		}

		p {
			max-width: 860px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.base-heading {
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
		text-align: left;
	}
}

.breadcrumbs-container {
	display: flex;
	justify-content: space-between;
}

.breadcrumbs-container + .base-section {
	.container {
		padding-top: 0;
	}
}

//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
	.base-section {
		.container {
			padding-top: $baseline * 2;
			padding-bottom: $baseline * 2;
		}

		.container > header {
			margin-bottom: $baseline * 2;

			.base-heading:last-child {
				margin-bottom: $baseline * 4;
			}
		}
	}
}

//Up to phone landscape - up to 767px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {
	.breadcrumbs-container + .base-section {
		.container {
			padding-top: $baseline;
		}
	}

	.base-section {
		.container > header {
			.base-heading:last-child {
				margin-bottom: $baseline * 2;
			}
		}
	}
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	.base-section {
		.container {
			padding-top: $baseline;
			padding-bottom: $baseline;
		}
	}
}
