.static-site-background{
	background-color: white;
	padding: 15px 15px 0;
	h2{
		margin-top: 0;
		transform: unset;
		line-height: unset;
	}
}

.maps-tabs {

	h1, h2, h3, h4, h5{
		color: #1b2437;
	}

	.navigation-footer{
		font-size: 16px;
		font-style: italic;
		line-height: 1.5;
		color: rgba(0, 0, 0, 0.5);
		padding-bottom: 64px;
		padding-top: 19px;
	}


	.navigation {

		section{
			padding: 20px 20px 5px;
			//border: solid 1px #e39fa6;
			border-radius: 3px;
			border: solid 1px #f1f4ff;
			background-color: #fbfbfb;
			color: $color-white;
			a {
				line-height: 2;
			}
		}



		main {
			input {
				display: none;
			}
		}
		label {
			display: inline-block;
			margin: 0;
			margin-right: 19px;
			border-radius: 3px;
			padding: 12px 25px;
			font-weight: 600;
			text-align: center;
			line-height: 1.5;
			color: #082e88;
			font-size: 16px;
			background-color: #f1f4ff;
		}
		label:before {
			font-family: "OpenSans";
			font-weight: normal;
		}
	}



	.navigation label[for*="1"]:before {
		content: none;
	}

	.navigation label[for*="2"]:before {
		content: none;
	}

	.navigation label[for*="3"]:before {
		content: none;
	}

	.navigation label[for*="4"]:before {
		content: none;
	}

	.navigation label:hover {
		border-radius: 3px 3px 0 0;
		border-top: solid 1px #9fb0e3;
		border-bottom: solid 1px #9fb0e3;
		background-color: #11347e;
		color: $color-white;
		cursor: pointer;
	}

	.navigation{
		input:checked + label {
			border-radius: 3px 3px 0px 0px;
			//border: solid 1px #e39fa6;
			border-bottom: solid 1px #9fb0e3;
			background-color: #11347e;
			color: $color-white;
		}
	}

	.map-preview-article{
		text-align: center;
		p,a{
			text-align: center;
			a:hover{
				text-decoration: none;
			}
			padding-left: 1.5em;
			padding-right: 1.5em;
		}
		img {
			max-height: 200px;
			width: auto;
			object-fit: contain;
			margin: auto;
			padding: 0;
			display: block;
		}
		.map-box {
			background: #f5f5f5;
			border-radius: 10px;
			margin: .25em;
			height: 450px;

			p.map_name {
				//height: 74px;
				overflow: hidden;
			}
			p.download {
				text-align: right!important;
				a.btn {
					min-width: 100px;
				}
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}
		height: 470px
	}


	#tab1map:checked ~ #content1map,
	#tab2map:checked ~ #content2map,
	#tab4map:checked ~ #content4map,
	#tab3map:checked ~ #content3map {
		display: block;
	}
	//Tablet portrait - from 768px to 991px
	@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {
		.navigation label {
			margin: 0;
			padding: 10px;
			font-family: "OpenSans", sans-serif;
			font-size: 18px;
		}
	}
	//Phone landscape - from  576px to 767px
	@media (max-width: map-get($grid-breakpoints, md) - 1px) {
		.navigation label {
			padding: 8px 10px;
			font-size: 16px;
			border-radius: 3px !important;
			transform: translateY(3px);
		}
		.navigation {
			padding-top: 20px;
			//margin-left: 75px;
			//margin-right: 75px;
		}
	}
	//Phone portrait - up to 575px
	@media (max-width: map-get($grid-breakpoints, sm) - 1px) {

		.navigation {
			//padding-top: 100px;
		}

		.navigation section {
			padding-left: 20px;
		}
	}
}

#regional-documents{
	.documents-downloads,
	.dynamic-content {
		p > img,
		td > img {
			display: inline-block;
		}
		a {
			line-height: 2rem;
		}
	}


	.table-fixed{
		table-layout: fixed;
		min-width: 800px;
		th, td{
			width: 170px;
		}
		th:first-of-type, td:first-of-type{
			width: 200px;
		}
	}

}

.rmaps {
	table th:first-child {
		background-color: #11347e;
	}

	table td:first-child {
		background-color: white;
	}
}