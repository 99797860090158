/* purgecss start ignore */

//Employees item
.employees-item {
	margin-bottom: $baseline * 4;

	.employee-img {
		margin: 0 auto;
		margin-bottom: $baseline;
	}

	& > * {
		max-width: 100%;
	}
}

.employees-item-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

//Employee img
.employee-img {
	flex-shrink: 0;
	position: relative;
	overflow: hidden;
	background: $image-placeholder-bg-color;
	border-radius: 100%;

	width: 150px;
	height: 150px;
}

//Employee name
.employee-name {
	margin-bottom: 0;
	text-align: center;
}

//Employee title
.employee-title {
	font-size: $small-text-font-size;
	transform: translateY($small-text-offset);
	margin-bottom: $baseline;
	text-align: center;
}

//Employee text
.employee-text {
	text-align: center;
	font-size: $small-text-font-size;
}

.employee-name + .employee-text {
	margin-top: $baseline;
}

//Employee contact
.employee-contact {
	display: block;
	transform: translateY(5px);
	text-align: center;

	img {
		width: 16px;
		display: inline-block;
		transform: translateY(-3px);
		margin-right: 12px;
	}

	span {
		font-size: $small-text-font-size;
		color: $color-text;
	}
}

a.employee-contact {
	text-decoration: none;

	span {
		color: $link-text-color;
	}

	&:hover {
		span {
			text-decoration: underline;
		}
	}
}

.employee-email {
	img {
		transform: translateY(-2px);
	}
}

//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
	.employees-item {
		margin-bottom: $baseline * 3;
	}
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	.employees-item {
		max-width: 400px;
	}
}

/* purgecss end ignore */
