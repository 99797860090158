//Header main

.header-main {
	padding-top: 20px;
	margin-bottom: 20px;
}

.header-main .container {
	//background: $header-bg-color;
	display: block;
	margin: 0 auto;
	margin-bottom: 10px;
}

.header-block img {
	display: block;
	max-width: 230px;
	height: auto;
	float: left;
	transform: translateX(-15px) translateY(-10px);
}

.header-block {
}

.search_eu_projects .header-project {
	border-top: 1px solid #eae8e8;
	border-left: 1px solid #eae8e8;
	border-right: 1px solid #eae8e8;
}

.header-project {
	//margin-top: 2em;
	display: flex;
	justify-content: center;
	border-left: 1px solid #eae8e8;
	border-right: 1px solid #eae8e8;

	h1 {
		font-size: 1.2em;
	}
	h1::after {
		content: '';
		display: block;
		top: 5px;
		height: 1px;
		width: 45%;
		margin: auto;
		position: relative;
		background-color: #0b2f7b;

	}
}

.header-text {
	margin: 0 auto;
	width: 50%;
	padding-top: 38px;
	padding-right: 0;
	font-size: .9em;
}

.header-text p {
	//padding-top: 14px;
	transform: none;
	opacity: 0.51;
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #000000;
	float: left;

	padding-left: 30px;
	padding-right: 50px;
}

// Search box
.header-block .topnav {
	margin-top: 29px;
}

.topnav {
	overflow: auto;
	background-color: transparent;

}

.topnav .search-container {
	float: none;
	margin-left: auto;
	background: transparent;
}

.topnav input[type="text"] {
	min-width: 50px;
	flex: 1;
	outline: 0;
	font-size: 1em;
	color: #000;
	font-weight: 500;
	border: none;
	padding: 7px 50px 7px 12px;
	border-radius: 10px 0 0 10px;
	text-align: left;
	background: #fff;
	box-shadow:3px 3px 5px #999;
}

.topnav .search-container form {
	display: flex;
	margin: .5em;
}

.topnav .search-container button {
	background: #2372cf;
	font-size: 21px;
	height: 54px;
	width: 60px;
	border: none;
	cursor: pointer;
	overflow: hidden;
	border-radius: 10px;
	border-left: solid 1px #b9b9b9;
	position: relative;
	left: -8px;
	box-shadow:3px 3px 5px #999;

	&:hover {
		background: darken(#2372cf, 10%);
		transition: background-color 0.3s;
	}
	img {
		height: 25px;
		width: 25px;
		top: 10px;
		left: 25px;
		position: relative;
	}
}
.topnav svg {
	height: 15px;
	width: 16px;
	margin: 0px;
	path {
		fill: white;
	}
}


.homepage-header-block {
	display: flex;
	flex-grow: 1;
}

.homepage-header-left {
	flex-basis: 50%;
	flex-grow:0;
	align-self: center;
	h1 {
		font-weight: 100;
		font-size: 3em;
		color: #395492;
		margin-bottom: .5em;
	}
	p{
		color: #395492;
		font-size: 1.25em;
	}
	border-left: .5em #bed7f1 solid;
	padding-left: 1.5em;
	padding-top: 1em;
}

.homepage-header-right {
	flex-basis: 50%;
	flex-grow:0;
	padding: 0 4%;
}


//Desktop - from 1200px up
@media (min-width: map-get($grid-breakpoints, xl)) {
	.header-main {
		margin-bottom: 5px;
	}
	.header-block img {
		max-width: 300px;
	}
}
//Up to tablet landscape - up to 1199px
@media (max-width: map-get($grid-breakpoints, xl) - 1px) {
}
//Tablet landscape - from 992px to 1199px
@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1px) {
}
//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
	.header-brand {
		padding-top: 18px;
		height: auto;
	}
	.header-text {
		padding-top: 28px;
	}
	.header-main {
		//padding-bottom: 220px;
	}
}
//Tablet portrait - from 768px to 991px
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {
	.header-text p {
		font-size: 14px;
		margin: 5px;
		margin-top: 10px;
	}

	.header-text {
		display: none;
	}
	.header-main {
		padding-bottom: 20px;
	}
}
//Up to phone landscape - up to 767px
@media (max-width: map-get($grid-breakpoints, md) - 1px) {
	.header-text {
		margin: auto;
		width: 100%;
	}

	.header-text p {
		font-size: 14px;
		margin: 10px;
	}
}
//Phone landscape - from  576px to 767px
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
	.header-text {
		margin: auto;
		font-size: 14px;
		width: 100%;
	}
	.header-brand {
		width: 203px;
		height: auto;
	}
	.header-text {
		padding-top: 28px;
		width: 100%;
	}
	.header-text {
		display: none;
	}
	.header-main {
		padding-bottom: 0px;
	}
}
//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	.header-text p {
		margin-top: 100px;
	}
	.header-main {
		padding-bottom: 0px;
		margin-bottom: 0px;
	}
	.header-text {
		display: none;
	}

	.topnav {
		margin-top: 0px !important;
	}

	#logo {
		max-height: 100px;
	}
}
