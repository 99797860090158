//Newsletter section
.newsletter-section {
	margin-bottom: $baseline * 2;
}

//Newsletter strip
.newsletter-strip {
	background: $newsletter-bg-color;
	padding: $baseline * 1.5 0;
	margin-bottom: $baseline;

	.container {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
}

.newsletter-heading {
	color: $newsletter-text-color;
	margin-bottom: 0;
}

//Newsletter form
.newsletter-form {
	box-shadow: 0 0 0 1px $newsletter-border-color;
	display: flex;
	position: relative;

	.validator {
		position: absolute;
		left: 0;
		bottom: 0;
	}
}

.newsletter-input {
	background: $newsletter-bg-color;
	border: 0;
	width: 300px;
	height: $baseline * 2;
	padding: 0 20px;

	color: $newsletter-text-color;

	outline: none;

	&::placeholder {
		color: $color-white;
	}
}

.newsletter-submit {
	color: $newsletter-button-text-color;
	font-weight: $newsletter-button-text-font-weight;
	background: transparent;
	border: none;
	height: $baseline * 2;
	line-height: $baseline * 2;
	padding: 0 20px;
	flex-shrink: 0;
	font-size: $base-text-font-size;

	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

//Priavy policy link
.newsletter-privacy-policy-link {
	a {
		display: flex;
		justify-content: center;
		font-size: $smallest-text-font-size;

		align-items: center;

		img {
			width: 16px;
			margin-right: 10px;
		}
	}
}

//Up to tablet portrait - up to 991px
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
	//Newsletter strip
	.newsletter-strip {
		background: $newsletter-bg-color;
		padding: $baseline * 1.5 0;
		margin-bottom: $baseline;

		.container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			align-items: center;
		}
	}

	.newsletter-heading {
		margin-bottom: $baseline * 1.5;
		width: 100%;
		text-align: center;
	}
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	.newsletter-form {
		width: 100%;
		max-width: 420px;
	}

	.newsletter-input {
		width: 100%;
	}
}
