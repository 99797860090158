/* purgecss start ignore */
//WP Gallery
.gallery {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -12px;

	margin-top: $baseline * 2;
	margin-bottom: $baseline;
}

.gallery-item {
	width: 20%;
	margin-bottom: $baseline;
	padding: 0px 12px;
	display: flex;
	flex-direction: column;

	a {
		display: block;
		transition: opacity 0.2s ease;
		height: 100%;

		&:hover {
			opacity: 0.7;
		}
	}

	img {
		margin: 0 auto;
		width: 100%;
		height: 100%;
		object-fit: cover;
		font-family: "object-fit: cover;";
		border-radius: 0;
	}
}

.gallery-icon {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: $image-border-radius $image-border-radius 0 0;
	min-height: $baseline * 3;
	@extend .img-placeholder;

	&:last-child {
		border-radius: $image-border-radius;
	}
}

.gallery-columns-4 {
	.gallery-item {
		width: 25%;
	}
}

.gallery-columns-3 {
	.gallery-item {
		width: calc(100% / 3);
	}
}

.gallery-columns-2 {
	.gallery-item {
		width: calc(50% - 5px);
	}
}

.gallery-columns-1 {
	.gallery-item {
		width: 100%;
	}
}

// up to tablet portrait (including)
@media (max-width: map-get($grid-breakpoints, lg) - 1px) {
	/*----- WP Gallery ----*/
	.gallery {
		margin-left: -5px;
		margin-right: -5px;
	}

	.gallery-item {
		padding: 0px 5px;
	}
}

// phone portrait
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	.gallery-item {
		width: calc(50% - 5px) !important;
	}
}

//WP gallery full widht - no spaces between items
.wp-gallery-no-gutter {
	.gallery {
		margin-left: 0;
		margin-right: 0;
	}
	.gallery-item {
		padding: 0;
		margin-bottom: 0;
	}
}

/* purgecss end ignore */
