.signpost-section-no-img {
	.signpost-item-img {
		display: none;
	}

	.signpost-item-inner {
		background-color: $color-bg-01 !important;
		padding-top: $baseline;
		transition: transform .3s ease;

		p {
			padding: 0 15px;
		}
	}

	.signpost-item-inner-only-title {
		justify-content: center;
		align-items: center;
	}

	.signpost-item-title {
		padding: 0 15px;
	}

	.signpost-item-title-no-description {
		padding: $baseline 15px;
	}

	.btn-container,
	.btn {
		width: 100%;
	}
}