/*svg#cz-map {*/
/*  background:#e6e6e6;*/
/*}*/

#homepage-svg-map {
  display: inline-block;
  width: 500px;

  .cls-1{
    fill:#9e1e2c;
  }
  .cls-2,.cls-3{
    opacity:.43;
  }
  .cls-2,.cls-3,.cls-4,.cls-5,.cls-6 {
    fill:none;
  }
  .cls-2,.cls-3,.cls-4,.cls-6{
    stroke-width:.5px;
  }
  .cls-2,.cls-7{
    stroke:#f4f4f4;
  }
  .cls-3,.cls-4,.cls-5,.cls-6{
    stroke: #9e1e2c;
  }
  .cls-4{
    opacity:.69;
  }
  .cls-5{
    stroke-miterlimit:10;
  }
  .cls-8{
    opacity:.29;
  }
  .cls-6{
    opacity:.11;
  }
  .cls-9{
    stroke:#f6f6f6;
  }
  .cls-9,.cls-7{
    fill:#fff;stroke-width:4px;
  }

  .point {
    fill: transparent;
    stroke: #9e1e2c;
    cursor: pointer;
    stroke-width: 2;
    r: 7.07;
    pointer-events: none;
  }

  .point-inner {
    r: 7.07;
    fill: #9e1e2c;
    pointer-events: none;
  }

  @keyframes scale-point {
    to {
      r: 30;
    }
  }


  .region {
    fill: rgba(255, 2555, 255, 0.7);
    //stroke: #f6f6f6;
    stroke: #e0e0e0;
    transition: fill 0.3s ease;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 4;

  }

  .region:hover {
    fill: #ccc;
    cursor: pointer;
  }

  circle.point.connected {
    animation-iteration-count: infinite;
    animation-name: scale-point;
    animation-duration: 1s;
    animation-timing-function: linear;
  }


  .connection {
    fill: none;
    stroke: #9e1e2c;
    stroke-width: 1.5;
    /*stroke-dasharray: 5;*/
    opacity: 1;
    /*animation: dash-opacity .3s linear alternate infinite;*/
  }


  @keyframes dash-opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /*@font-face {*/
  /*  font-family: Source Sans Pro;*/
  /*  src: local("Source Sans Pro"), url("../fonts/SourceSansPro-Regular.ttf") format("truetype");*/
  /*  font-weight: normal;*/
  /*  font-style: normal;*/
  /*}*/
  /*@font-face {*/
  /*  font-family: Source Sans Pro;*/
  /*  src: local("Source Sans Pro"), url("../fonts/SourceSansPro-Semibold.ttf") format("truetype");*/
  /*  font-weight: bold;*/
  /*  font-style: normal;*/
  /*}*/
  /*@font-face {*/
  /*  font-family: Source Sans Pro;*/
  /*  src: local("Source Sans Pro"), url("../fonts/SourceSansPro-SemiboldItalic.ttf") format("truetype");*/
  /*  font-weight: bold;*/
  /*  font-style: italic;*/
  /*}*/
  /*@font-face {*/
  /*  font-family: Source Sans Pro;*/
  /*  src: local("Source Sans Pro"), url("../fonts/SourceSansPro-Italic.ttf") format("truetype");*/
  /*  font-weight: normal;*/
  /*  font-style: italic;*/
  /*}*/
  * {
    box-sizing: border-box;
  }

  :root {
    position: relative;
    height: 100%;
    min-height: 100%;
    font-family: arial, helvetica, sans-serif;
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    color: #3c4946;
  }

  body {
    height: 100%;
    margin: 0;
    font-size: 2.2rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  a {
    transition: color 0.25s;
    color: #7cb342;
  }
  a:hover {
    color: #558b2f;
  }

  h1,
  h3 {
    margin-top: 0;
    font-weight: normal;
    color: #7cb342;
    line-height: 100%;
  }

  h1 {
    margin-bottom: 1.5rem;
    font-size: 4.6rem;
  }

  h3 {
    margin-bottom: 0;
    font-size: 3.2rem;
  }
  }
  .visualwrapper {
    width: 100%;
    max-width: 1200px;
    min-height: 100%;
    margin: 0 auto;
    padding: 2rem 8rem;
    background-color: #fff;
  }

  .tooltip-example-right, .tooltip-example-left {
    position: absolute;
  }
  .tooltip-example-right {
    top: 0;
    right: 0;
  }
  .tooltip-example-left {
    top: 50%;
    left: 0;
  }

  .overflow-test {
    height: 35px;
    overflow: hidden;
    background-color: rgba(204, 204, 204, 0.5);
  }

  .no-display {
    display: none;
  }

  .tooltip-container {
    transform: translateZ(0);
    position: fixed;
    max-width: 200px;
    padding: 8px 10px 10px;
    font-size: 1.2rem;
    background-color: #2d2d2d;
    color: #fff;
    border-radius: 4px;
    opacity: 1;
    z-index: 10;
  }
  .tooltip-container[class*=" tooltip-"] {
    -webkit-animation: tooltip-anim 0.8s;
    animation: tooltip-anim 0.8s;
  }
  .tooltip-container::after {
    position: absolute;
    display: block;
    content: "";
  }
  .tooltip-container.tooltip-left::after {
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 6px 0 6px 8px;
    border-color: transparent transparent transparent #2d2d2d;
  }
  .tooltip-container.tooltip-right::after {
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 6px 8px 6px 0;
    border-color: transparent #2d2d2d transparent transparent;
  }
  .tooltip-container.tooltip-center-bottom::after, .tooltip-container.tooltip-center::after {
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-color: transparent;
  }
  .tooltip-container.tooltip-center::after {
    top: -8px;
    border-width: 0 6px 8px 6px;
    border-bottom-color: #2d2d2d;
  }
  .tooltip-container.tooltip-center-bottom::after {
    bottom: -8px;
    border-width: 8px 6px 0 6px;
    border-top-color: #2d2d2d;
  }

  [data-tooltip] {
    cursor: pointer;
    color: #7cb342;
    display: inline-block;
  }

  @keyframes tooltip-anim {
    0% {
      opacity: 0;
      transform: matrix(0.5, 0, 0, 0.8, 0, 0);
    }
    20% {
      transform: matrix(1.1, 0, 0, 1.1, 0, 0);
    }
    40% {
      opacity: 1;
    }
    70% {
      transform: matrix(1, 0, 0, 1, 0, 0);
    }
    100% {
      transform: matrix(1, 0, 0, 1, 0, 0);
    }
  }
  @-webkit-keyframes tooltip-anim {
    0% {
      opacity: 0;
      transform: matrix(0.5, 0, 0, 0.8, 0, 0);
    }
    20% {
      transform: matrix(1.1, 0, 0, 1.1, 0, 0);
    }
    40% {
      opacity: 1;
    }
    70% {
      transform: matrix(1, 0, 0, 1, 0, 0);
    }
    100% {
      transform: matrix(1, 0, 0, 1, 0, 0);
    }


/*# sourceMappingURL=tooltip.css.map */
  }

.krajske-ris-content-container {
  #homepage-svg-map {
    position: relative;
    float: right;
    max-width: 400px;
    min-width: 350px;
    width: 44%;
    z-index: 5;

    .cls-8 {
      opacity: 1;
    }
  }

  #tooltipText {
    font-size: 1rem;
    padding: 6px 9px;
  }
}


