


//modal
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

  h2 {
    margin-bottom: 15px;
  }
}

// Modal Content/Box
.modal-page {
  display: block;
  border-radius: 3px;
  border: solid 1px #f1f4ff;
  background-color: #ffffff;
  margin: 30px auto; /* 30px from the top and centered */
  //width: 60%;
  width: calc(100% - 30px);
  max-width: 853px;
}

.modal-content {
  padding: 16px 59px 27px 59px;

}

// The Close Button
span.close {
  color: #b9b9b9;
  float: right;
  font-size: 28px;
  padding: 16px 20px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-page .btn{
  margin: 0 auto;
  margin-top: 27px;
  margin-bottom: 3px;
}


//Phone landscape - from  576px to 767px
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
  .modal-page {
    width: 95%;
  }
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
  .modal-page {
    width: 90%;
  }
}
