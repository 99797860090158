//Posts item
.posts-item {
	display: flex;
	margin-bottom: $baseline * 2;
}

//Img
.posts-item-img {
	flex-shrink: 0;
	margin-right: 21px;

	align-self: flex-start;

	border-radius: $image-border-radius;

	background: $image-placeholder-bg-color;

	width: 192px;
	max-width: 100%;
	position: relative;

	&:hover {
		img {
			opacity: 0.7;
		}
	}

	&::after {
		content: "";
		position: relative;
		display: block;
		width: 100%;
		padding-bottom: 100%;
	}

	img {
		position: absolute;
		border-radius: $image-border-radius;
		max-height: 100%;
	}
}

//Heading
.posts-item-heading {
	margin-bottom: $baseline;
}

//Tags bar
.posts-item-tags-bar {
	display: flex;
	flex-wrap: wrap;
}

.posts-item-tag {
	background: $color-bg-01;
	//box-shadow: inset 0 0 0 1px $color-bg-02;
	font-size: $smallest-text-font-size;
	margin-bottom: $baseline;
	//line-height: 20px;
	padding: 0 10px;
	//margin: 2px 0;
	margin-right: 10px;
	color: $color-text;
	border-radius: $border-radius-base;
}

a.posts-item-tag {
	&:hover {
		color: $color-primary;
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

//Bottom bar
.posts-item-bottom-bar {
	display: flex;
	font-size: $smallest-text-font-size;
	font-weight: 700;
	flex-wrap: wrap;
}

.posts-item-author {
	margin-right: 30px;
}

.posts-item-date {
	margin-right: 30px;
}

.posts-item-read {
	margin-left: auto;
	display: flex;
	align-items: center;

	span {
		margin-right: 5px;
	}

	svg {
		height: $smallest-text-font-size * 0.8;
		fill: $link-text-color;
	}
}

//Phone landscape - from  576px to 767px
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
	//Img
	.posts-item-img {
		width: 150px;

		&::after {
			padding-bottom: 100% / 3 * 4;
		}
	}
}

//Phone portrait - up to 575px
@media (max-width: map-get($grid-breakpoints, sm) - 1px) {
	//Posts item
	.posts-item {
		flex-direction: column;

		margin-left: auto;
		margin-right: auto;
	}

	.posts-item-img {
		width: 300px;
		margin-bottom: $baseline;

		&::after {
			padding-bottom: 100% / 4 * 3;
		}
	}

	//Homepage only
	.home {
		.posts-item-img {
			display: none;
		}
	}
}
