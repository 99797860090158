.region-tabs {
	.navigation-section {
		min-width: 100%;
		border-radius: 3px;
		border: solid 1px #f1f4ff;
		background-color: #fbfbfb;
	}

	.nav-pills {
		width: 100%;
		margin-left: 15px;

		#pills-1-tab.active,
		#pills-2-tab.active,
		#pills-3-tab.active,
		#pills-4-tab.active,
		#pills-5-tab.active,
		#pills-6-tab.active {
			background-color: #11347e;
			color: $white;
		}

		.active .nav-link {
			background-color: #11347e;
			color: $white;
		}

		.nav-link {
			text-decoration: none;
			padding: 12px 30px;
			margin-right: 18px;
			height: 48px;
			font-weight: 600;
			border-radius: 3px;
			background-color: #f1f4ff;

			&:active {
				background-color: #11347e;
				color: $white;
			}

			&:hover {
				background-color: #11347e;
				color: $white;
			}

			a {
				text-decoration: none;

				&:active {
					text-decoration: none;
				}
			}
		}
	}

	.navigation-section {
		padding: 41px 42px 20px;
		margin-bottom: 73px;

		h2{
			font-size: 28px;
			padding-bottom: 16px;
		}

		a {
			line-height: 1.75;

			a:hover {
				text-decoration: none;
			}
		}
	}

	table {
		td,
		tr {
			border-radius: 3px;
			border: solid 1px #d7d7d7;
			background-color: #ffffff;
			height: 48px;
			text-align: left;
		}

		td {
			padding: 5px 12px 5px 20px;
		}
	}

	.navigation-labels {
		.city-list {
			padding-top: 19px;
			padding-left: 20px;
			padding-bottom: 99px;

			a {
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}
